import { StoresOptions } from './../../core/models/stores.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';
import { LocationService } from 'src/app/core/services/location/location.service';
import { StoresService } from 'src/app/core/services/stores/stores.service';
import { OffersService } from 'src/app/core/services/offers/offers.service';
import { FormControl, FormGroup } from '@angular/forms';
import { BrowserService } from 'src/app/core/services/browser/browser.service';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-offers',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css'],
})
export class StoresComponent implements OnInit {
  FilterForm: FormGroup;
  currentLang;
  categoryId;
  stores;
  categories = [];
  categoryName;
  currentCategory;
  totalPages = 0;
  itemsPerPage = 0;
  currentPage = 0;
  totalItems = 0;
  currentCatId = 1;
  currentCatAds;
  showFilter = false;
  notItemsAvalible = false;
  StoreFilter: StoresOptions = {
    page: 1,
  };

  constructor(
    private route: ActivatedRoute,
    private storesService: StoresService,
    private activated: ActivatedRoute,
    private router: Router,
    private langService: LanguageService,
    private categoriesService: CategoriesService,
    private locationService: LocationService,
    private offerServices: OffersService,
    private browserService: BrowserService
  ) {}

  ngOnInit(): void {
    this.browserService.updateBrowserTitle(' - المتاجر');
    this.StoreFilter.lat = this.locationService.getLocalCoordinates().lat;
    this.StoreFilter.lng = this.locationService.getLocalCoordinates().lng;
    if (this.categories.length == 0) {
      this.getAllCategories();
    }
    this.getQueryParams();
    this.initForm();

    this.langService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );
  }

  initForm() {
    this.FilterForm = new FormGroup({
      most_discount: new FormControl(''),
      nearest: new FormControl(''),
      newest_offers: new FormControl(''),
      most_selling: new FormControl(''),
      price: new FormControl(''),
    });
  }
  private getParams(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.StoreFilter.category_id = params.id;
        this.currentCategory = this.categories.filter(
          (el) => el.id == params?.id
        )[0];
        this.currentCatAds = this.categories.filter(
          (el) => el.id == params?.id
        )[0].ads;
        this.categoryName = this.currentCategory?.name;
      } else {
        this.currentCategory = this.categories[0];
        this.StoreFilter.category_id = this.categories[0].id;
        this.currentCatAds = this.categories.filter(
          (el) => el.id == this.categories[0].id
        )[0].ads;
        this.categoryName = this.categories[0].name;
      }

      this.getStores();
    });
  }

  private getQueryParams(): void {
    this.activated.queryParams.subscribe((qParams) => {
      if (qParams['page']) {
        this.StoreFilter.page = +qParams['page'] || 1;
        this.getStores();
      }
    });
  }

  getStores() {
    this.storesService.getStores(this.StoreFilter).subscribe((res: any) => {
      this.stores = res.data.data;
      this.totalPages = res.data.last_page;
      this.itemsPerPage = res.data.per_page;
      this.currentPage = res.data.current_page;
      this.totalItems = res.data.total;

      this.notItemsAvalible = this.stores.length === 0 ? true : false;
    });
  }

  getAllCategories() {
    this.categoriesService.getAllCategories().subscribe((res: any) => {
      this.categories = res.data.data;

      this.getParams();
    });
  }

  openStore(storeId) {
    this.storesService.getStoreDetails(storeId).subscribe((res: any) => {
      this.storesService.storeDetailsOpen.next(res.data);
    });
  }

  openAds(actionType, actionValue) {
    switch (actionType) {
      case 'external_link':
        window.open(actionValue, '_blank').focus();
        break;

      case 'category':
        this.router.navigate(['/offers', actionValue]);
        break;

      case 'offer':
        // this.openOffer(actionValue);
        this.storesService.offerDetailsOpen.next(actionValue);
        break;

      case 'store':
        this.openStore(actionValue);
        break;

      default:
        this.router.navigate(['/home']);
    }
  }

  openOffer(offerId: string) {
    this.offerServices.getOfferDetails(offerId).subscribe((res: any) => {
      this.storesService.storeDetailsOpen.next(res.data);
    });
    // this.storesService.offerDetailsOpen.next(offerId);
  }

  onPageChange(page) {
    this.currentPage = +page;
    this.StoreFilter.page = +page;
    this.router.navigate([], { queryParams: { page } });
  }

  SubmitFilters() {
    this.showFilter = false;
  }
}
