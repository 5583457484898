<div class="polcies-sec">
  <div class="container">
    <div class="ar">
      <h2 class="page-title">الشروط والاحكام</h2>

      <p class="polcies-text">
        فيما يلي البنود والشروط التي تخص شروط الاستخدام والدخول لصفحات تطبيق
        وموقع حسومات com تعطى الكلمات والتعابير الواردة في هذه الاتفاقية المعاني
        المحددة لها كما يلي : حسومات: ويقصد بها مؤسسة دار مملكة الريادة لتقنيه
        المعومات. الموقع: <br /><br />
        يقصد به أينما ورد في الاتفاقية بموقع وتطبيق حسومات كما وتعتبر كافة
        الصفحات و الروابط والأدوات والخواص المتفرعة من الموقع والتطبيق والمتعلقة
        بالخدمات المقدمة منه جزء لا يتجزء من موقع وتطبيق حسومات الإلكتروني.
        العميل: ويقصد به الجهة التي تطلب الخدمة من الموقع سواء كانت هذه الجهة
        شخص طبيعي أو شخص أعتباري. الشركاء: ويقصد به الجهة التي تعلن عن منتجاتها
        وخدماتهاعلى موقع حسومات. الاتفاقية: ويقصد بها اتفاقية شروط واحكام
        استخدام موقع حسومات الإلكتروني والتي تتضمن كافة الاحكام والشروط الموضحة
        للعميل والتي تحكم وتنظم عملية إستخدام العميل لموقع وتطبيق حسومات
        الإلكتروني. يقر ويوافق العميل على ان إستخدامه للموقع يعتبر إقرار صريح
        وواضح ويفيد بقيامه بقراءة وفهم بنود وأحكام هذه الاتفاقية و طريقة
        الاستخدام
      </p>
    </div>

    <div class="en">
      <h2 class="page-title">Introduction Terms and conditions</h2>

      <p class="polcies-text">
        Welcome to 7usoomat.com. These are the terms and conditions governing
        your access to and use of the website 7usoomat.com and its related
        sub-domains, sites, services and tools (the “Site”). By accepting these
        terms and conditions, and by using the Site, you represent that you
        agree to comply with these terms and conditions with 7usoomat.com (“we”,
        “us” or “7usoomat.com”) in relation to your use of the Site (the “User
        Agreement”). This User Agreement is effective upon acceptance. If you do
        not agree to be bound by this User Agreement please do not access,
        register with or use this Site. Partners “: It means the party that
        advertise their products and services on 7usommat.com Before you may
        become or continue as a member of the Site, you must read, agree with
        and accept this User Agreement and 7usoomat.com’s Privacy Policy (the
        “Privacy Policy”). You should read this User Agreement and the Privacy
        Policy and access and read all further linked information referred to in
        thi
      </p>
    </div>
  </div>
</div>
