<div
  class="disable-background"
  [ngClass]="{ show: showAuth || showProfileData }"
></div>

<div class="auth-form" *ngIf="showAuth">
  <button
    type="button"
    class="btn-close d-block ms-auto mb-4"
    aria-label="Close"
    (click)="onCloseAuth()"
  ></button>
  <form
    class="login-form"
    (ngSubmit)="onLogin()"
    #loginForm="ngForm"
    *ngIf="loginMood && !showVerfication"
  >
    <div class="user-info">
      <h3 class="title">{{ "Login And Enjoy The Offers" | translate }}</h3>
      <p class="sub-title">
        {{
          "Enter your number to receive a verification code in an SMS"
            | translate
        }}
      </p>
      <div class="phone-field">
        <div class="country-key">
          <p class="key">966</p>
          <img src="assets/images/ic_soudi.svg" />
        </div>
        <input
          class="form-control form-field"
          type="text"
          placeholder="5xx xxx xxx"
          name="phone"
          [(ngModel)]="loginData.field"
          required
          pattern="[0-9]{1,9}"
          minlength="9"
          maxlength="9"
        />
      </div>
      <p *ngIf="showLoginError" class="text-danger m-0">
        {{ "Your number is not registered with us" | translate }}
      </p>

      <ng-select
        placeholder="{{ 'City' | translate }}"
        class="city-dropdown form-field"
        name="city"
        [(ngModel)]="loginData.city_id"
        required
      >
        <ng-option *ngFor="let city of cities" [value]="city.id">
          <a style="color: #7e7e7e"> {{ city.name }}</a>
        </ng-option>
      </ng-select>
    </div>
    <button
      class="btn btn-success login-btn"
      type="submit"
      [disabled]="loginForm.invalid"
    >
      {{ "Send Code" | translate }}
    </button>

    <div class="form-toggle">
      <span class="text">{{ "Don't have an account?" | translate }}</span>
      <span class="toggler" (click)="loginMood = !loginMood">
        {{ "Create a new account" | translate }}
      </span>
    </div>
  </form>
  <form
    class="register-form"
    (ngSubmit)="onTempRegister()"
    #registerForm="ngForm"
    *ngIf="!loginMood && !showVerfication"
  >
    <div class="user-info">
      <h3 class="title">{{ "Create a new account" | translate }}</h3>
      <p class="sub-title">
        {{
          "Enter your Info to receive a verification code in an SMS" | translate
        }}
      </p>

      <input
        class="form-control form-field"
        type="text"
        placeholder="{{ 'Full Name' | translate }}"
        name="name"
        [(ngModel)]="registerData.name"
        required
      />

      <input
        class="form-control form-field"
        type="email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        placeholder="{{ 'Email' | translate }}"
        name="email"
        [(ngModel)]="registerData.email"
        [ngClass]="{ error: showEmailError }"
        required
      />

      <p *ngIf="showEmailError" class="text-danger">
        {{ "Email is already registered." | translate }}
      </p>

      <ng-select
        placeholder="{{ 'Gender' | translate }}"
        class="gender-dropdown form-field"
        name="gender"
        [(ngModel)]="registerData.gender"
        required
      >
        <ng-option value="male">
          <a style="color: #7e7e7e">{{ "Male" | translate }}</a>
        </ng-option>
        <ng-option value="female">
          <a style="color: #7e7e7e">{{ "Female" | translate }}</a>
        </ng-option>
      </ng-select>

      <div class="phone-field">
        <div class="country-key">
          <p class="key">966</p>
          <img src="assets/images/ic_soudi.svg" />
        </div>
        <input
          class="form-control form-field"
          type="text"
          placeholder="5xx xxx xxx"
          name="phone"
          [(ngModel)]="registerData.phone"
          pattern="[0-9]{1,9}"
          maxlength="9"
          minlength="9"
          required
        />
      </div>

      <p *ngIf="showRegisterError" class="text-danger m-0">
        {{ phoneErrorMessage }}
      </p>

      <ng-select
        placeholder="{{ 'Nationality' | translate }}"
        class="city-dropdown form-field"
        name="city"
        [(ngModel)]="registerData.nationality_id"
        required
      >
        <ng-option
          *ngFor="let nationality of nationalities"
          [value]="nationality.id"
        >
          <a style="color: #7e7e7e"> {{ nationality.name }}</a>
        </ng-option>
      </ng-select>

      <input
        type="text"
        onfocus="(this.type='date')"
        class="form-control form-field"
        name="birthdate"
        placeholder="{{ 'Birth Date' | translate }}"
        [(ngModel)]="registerData.birthdate"
        required
      />
    </div>
    <button
      class="btn btn-success login-btn"
      type="submit"
      [disabled]="registerForm.invalid"
    >
      {{ "Send Code" | translate }}
    </button>

    <div class="form-toggle">
      <span class="text">{{ "Have a registered account?" | translate }}</span>
      <span class="toggler" (click)="loginMood = !loginMood">{{
        "Login" | translate
      }}</span>
    </div>
  </form>
  <form
    class="activation-form"
    #activationForm="ngForm"
    *ngIf="showVerfication"
  >
    <div class="user-info">
      <h3 class="title">{{ "Enter The Verification Code" | translate }}</h3>

      <input
        class="form-control form-field activation-field"
        type="text"
        autocomplete="off"
        name="code"
        [(ngModel)]="activationData.code"
        required
        pattern="[0-9]{1,5}"
        maxlength="5"
        (input)="onCheckActivationCode($event.target.value)"
      />
    </div>
    <p *ngIf="activationWrog" class="text-danger my-4 text-center">
      {{
        "The verification code is incorrect, please try again later."
          | translate
      }}
    </p>

    <div class="form-toggle">
      <span class="toggler" (click)="showVerfication = false">
        {{ "Try another mobile number" | translate }}
      </span>
    </div>
  </form>
</div>

<app-user-profile
  [ProfileData]="ProfileData"
  *ngIf="showProfileData"
></app-user-profile>
