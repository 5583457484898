<app-store-details></app-store-details>
<div class="container" [ngClass]="{ en: currentLang == 'en' }">
  <!-- header -->
  <header class="page-header">
    <div class="row align-items-center">
      <div class="col-12 col-lg-4">
        <h2 class="page-title">{{ categoryName }}</h2>
        <div class="breadcrumb">
          <a [routerLink]="['/home']" class="link-item breadcrumb-item">{{
            "Home" | translate
          }}</a>
          <span class="spliter">></span>
          <a [routerLink]="['/offers']" class="link-item breadcrumb-item">
            {{ "Offers" | translate }}
          </a>
          <span class="spliter">></span>
          <span class="breadcrumb-item">{{ categoryName }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-8 d-none d-lg-block">
        <ul class="tag-list">
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">الكل</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">ترفية</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">العناية بالسيارات</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">الكترونيات</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">مطاعم</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <div class="row">
    <div class="col-sm-12 col-lg-3">
      <div class="sidebar-list category-menu">
        <div class="list-title">{{ "Categories" | translate }}</div>
        <ul class="category-list">
          <li
            class="cat-item"
            *ngFor="let cat of categories"
            [routerLink]="['/offers/', cat.id]"
            [queryParams]="{ page: '1' }"
            routerLinkActive="cat-item-active"
          >
            <div class="cat-info">
              <img [src]="cat.icon" alt="cat-img" class="cat-img" />
              <span class="cat-name"> {{ cat.name }} </span>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="sidebar-list category-ads" *ngIf="currentCatAds">
        <img
          alt="homr ads"
          [defaultImage]="'assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="currentCatAds.web_image_en"
        />

        <button
          class="btn shop-btn"
          *ngIf="currentCatAds?.action != 'none'"
          (click)="openAds(currentCatAds?.action, currentCatAds?.value)"
        >
          تصفح الأن
        </button>
      </div> -->
    </div>

    <div class="col-sm-12 col-lg-9">
      <section class="sec-header">
        <div class="filters">
          <form
            class="cat-search-form"
            (ngSubmit)="customSearch(searchFrom)"
            #searchFrom="ngForm"
          >
            <input
              class="form-control search-input"
              name="offers_search"
              ngModel
              type="search"
              placeholder="{{ 'Search' | translate }}..."
            />
            <button class="btn search-btn" type="submit">
              <img src="../../../assets/images/icons/search-icon.png" alt="" />
            </button>
          </form>

          <div class="filter-toggle-btn" (click)="showFilter = !showFilter">
            <span> {{ "Filter" | translate }} </span>
            <img
              src="assets/images/icons/icon_filter_yellow.svg"
              alt="icon"
              class="filter-icon"
              *ngIf="checkFilterActivation()"
            />
            <img
              src="assets/images/icons/icon_filter.svg"
              alt="icon"
              class="filter-icon"
              *ngIf="!checkFilterActivation()"
            />
          </div>

          <form
            class="filter-form"
            [ngClass]="{ show: showFilter }"
            [formGroup]="FilterForm"
            (ngSubmit)="SubmitFilters()"
          >
            <label for="highest_discount" class="form-radio-label">
              <input
                type="checkbox"
                id="highest_discount"
                formControlName="highest_discount"
                class="form-radio-input"
                [(ngModel)]="discountChecked"
                (change)="
                  setFilterItem('highest_discount', discountChecked ? '1' : '0')
                "
              />
              {{ "Most Discount" | translate }}
            </label>
            <!-- 
            <label for="nearest" class="form-radio-label">
              <input
                type="checkbox"
                id="nearest"
                formControlName="nearest"
                class="form-radio-input"
              />
              الاقرب اليك</label
            > -->

            <label for="new_offers" class="form-radio-label">
              <input
                type="checkbox"
                id="new_offers"
                formControlName="new_offers"
                class="form-radio-input"
                [(ngModel)]="offersChecked"
                (change)="
                  setFilterItem('new_offers', offersChecked ? '1' : '0')
                "
              />
              {{ "Newest Offers" | translate }}
            </label>

            <label for="best_seller" class="form-radio-label">
              <input
                type="checkbox"
                id="best_seller"
                formControlName="best_seller"
                class="form-radio-input"
                value="1"
                [(ngModel)]="sellerChecked"
                (change)="
                  setFilterItem('best_seller', sellerChecked ? '1' : '0')
                "
              />
              {{ "Most Selling" | translate }}
            </label>

            <label for="price_order" class="form-radio-label">
              <input
                type="radio"
                id="price_order"
                formControlName="price_order"
                class="form-radio-input"
                value="asc"
                [(ngModel)]="OfferFilter.price_order"
              />
              {{ "Price From Lowest To Highest" | translate }}
            </label>

            <label for="price_order" class="form-radio-label">
              <input
                type="radio"
                id="price_order"
                formControlName="price_order"
                class="form-radio-input"
                value="desc"
                [(ngModel)]="OfferFilter.price_order"
              />
              {{ "Price From Highest To Lowest" | translate }}
            </label>

            <button type="submit" class="filter-submit-btn">
              {{ "Apply" | translate }}
            </button>

            <button
              type="button"
              class="btn btn-danger"
              [ngClass]="{ 'd-none': !checkFilterActivation() }"
              (click)="clearFilter()"
            >
              {{ "Reset" | translate }}
            </button>
          </form>
        </div>

        <ul class="category-nav-list">
          <li
            class="nav-item"
            [routerLink]="['/offers/', OfferFilter.category_id]"
            [queryParams]="{ page: '1' }"
            routerLinkActive="active"
          >
            {{ "Offers" | translate }}
          </li>
          <li
            class="nav-item"
            [routerLink]="['/stores/', OfferFilter.category_id]"
            [queryParams]="{ page: '1' }"
            routerLinkActive="active"
          >
            {{ "Stores" | translate }}
          </li>
        </ul>
      </section>

      <!-- category ads section  -->
      <section
        class="category-ads mt-4"
        *ngIf="currentCatAds"
        (click)="openAds(currentCatAds?.action, currentCatAds?.value)"
      >
        <img
          alt="home ads"
          [defaultImage]="'assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="currentCatAds.web_image_en"
        />
        <!-- 
        <button
          class="btn shop-btn"
          *ngIf="currentCatAds?.action != 'none'"
          (click)="openAds(currentCatAds?.action, currentCatAds?.value)"
        >
          تصفح الأن
        </button> -->
      </section>

      <section class="offers-sec">
        <div class="no-items" *ngIf="notItemsAvalible">لا توجد عروض متوفرة</div>
        <div class="offers-container">
          <div
            class="offer-item"
            (click)="openOffer(offer?.id)"
            *ngFor="
              let offer of offers
                | paginate
                  : {
                      currentPage: currentPage,
                      itemsPerPage: itemsPerPage,
                      totalItems: totalItems
                    }
            "
          >
            <img
              alt="offer-img"
              class="offer-img"
              [defaultImage]="'../../../assets/images/loading_store.svg'"
              [errorImage]="'../../../assets/images/error-img.svg'"
              [useSrcset]="true"
              [lazyLoad]="offer.image"
            />
            <div class="offer-body">
              <div class="offer-counter">
                <app-count-down [endDate]="offer.end_at"></app-count-down>
              </div>
              <div class="offer-details">
                <h2 class="offer-name">
                  {{ offer.title }}
                </h2>
                <p class="offer-vendor">
                  {{ "By" | translate }} <span> {{ offer.store_name }} </span>
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="offer-price" *ngIf="offer?.type !== 'general'">
                    {{ offer.price_after }} {{ "Rial" | translate }}
                  </div>

                  <div
                    class="offer-price discount-amount"
                    *ngIf="offer?.type === 'general'"
                  >
                    {{ "Discount" | translate }} {{ offer.discount_value }} %
                  </div>

                  <div class="add-btn">
                    <button class="btn">
                      <img
                        src="../../../assets/images/icons/icon-cart.svg"
                        alt="cart icon"
                        class="cart-icon"
                      />
                      <span> {{ "Redeem" | translate }} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <paging-controls
          [isItemsPerPage]="false"
          (pageChange)="onPageChange($event)"
          maxSize="7"
          *ngIf="!notItemsAvalible"
        ></paging-controls>
      </section>
    </div>
  </div>
</div>
