<app-store-details></app-store-details>
<div class="container" [ngClass]="{ en: currentLang == 'en' }">
  <!-- header -->
  <header class="page-header">
    <div class="row align-items-center">
      <div class="col-12 col-lg-4">
        <h2 class="page-title">{{ categoryName }}</h2>
        <div class="breadcrumb">
          <a [routerLink]="['/home']" class="link-item breadcrumb-item">{{
            "Home" | translate
          }}</a>
          <span class="spliter">></span>
          <a [routerLink]="['/offers']" class="link-item breadcrumb-item">{{
            "Stores" | translate
          }}</a>
          <span class="spliter">></span>
          <span class="breadcrumb-item">{{ categoryName }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-8 d-none d-lg-block">
        <ul class="tag-list">
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">الكل</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">ترفية</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">العناية بالسيارات</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">الكترونيات</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">مطاعم</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <div class="row">
    <div class="col-sm-12 col-lg-3">
      <div class="sidebar-list category-menu">
        <div class="list-title">{{ "Categories" | translate }}</div>
        <ul class="category-list">
          <li
            class="cat-item"
            *ngFor="let cat of categories"
            [routerLink]="['/stores/', cat.id]"
            [queryParams]="{ page: '1' }"
            routerLinkActive="cat-item-active"
          >
            <div class="cat-info">
              <img [src]="cat.icon" alt="cat-img" class="cat-img" />
              <span class="cat-name"> {{ cat.name }} </span>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="sidebar-list category-ads" *ngIf="currentCatAds">
        <img
          alt="homr ads"
          [defaultImage]="'../../../assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="currentCatAds.web_image_en"
        />

        <button
          class="btn shop-btn"
          *ngIf="currentCatAds?.action != 'none'"
          (click)="openAds(currentCatAds?.action, currentCatAds?.value)"
        >
          تصفح الأن
        </button>
      </div> -->
    </div>

    <div class="col-sm-12 col-lg-9">
      <section class="sec-header">
        <ul class="category-nav-list">
          <li
            class="nav-item"
            [routerLink]="['/offers/', StoreFilter.category_id]"
            routerLinkActive="active"
          >
            {{ "Offers" | translate }}
          </li>
          <li
            class="nav-item"
            [routerLink]="['/stores/', StoreFilter.category_id]"
            routerLinkActive="active"
          >
            {{ "Stores" | translate }}
          </li>
        </ul>
      </section>

      <!-- category ads section  -->
      <section class="category-ads mt-4" *ngIf="currentCatAds">
        <img
          alt="home ads"
          [defaultImage]="'assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="currentCatAds.web_image_en"
        />

        <button
          class="btn shop-btn"
          *ngIf="currentCatAds?.action != 'none'"
          (click)="openAds(currentCatAds?.action, currentCatAds?.value)"
        >
          {{ "View Now" | translate }}
        </button>
      </section>

      <section class="stores-sec">
        <div class="no-items" *ngIf="notItemsAvalible">
          {{ "No Available Stores" | translate }}
        </div>

        <div class="stores-container">
          <div
            class="store-item"
            (click)="openStore(store.id)"
            *ngFor="
              let store of stores
                | paginate
                  : {
                      currentPage: currentPage,
                      itemsPerPage: itemsPerPage,
                      totalItems: totalItems
                    }
            "
          >
            <div
              class="store-img"
              [ngStyle]="{ 'background-image': 'url(' + store.image + ')' }"
            ></div>
            <div class="store-body">
              <h2 class="store-name">{{ store.name }}</h2>
              <div class="store-details">
                {{ store.description | cutLongString }}
              </div>
              <div class="store-location" *ngIf="store.distance">
                <img
                  src="../../../assets/images/icons/ic_location.svg"
                  alt="location icon"
                />
                <span class="distance">{{ store.distance }} كم</span>
              </div>
            </div>
          </div>
        </div>

        <paging-controls
          [isItemsPerPage]="false"
          (pageChange)="onPageChange($event)"
          maxSize="7"
          *ngIf="!notItemsAvalible"
        ></paging-controls>
      </section>
    </div>
  </div>
</div>
