<app-auth></app-auth>

<div class="mobile-menu d-flex d-lg-none" [ngClass]="{ show: showMobileList }">
  <div class="hidder" (click)="showMobileList = !showMobileList"></div>
  <div class="menu" [ngClass]="{ en: currentLang == 'en' }">
    <section class="top-header">
      <div class="logo">
        <img src="../../../assets/images/logo-180px.png" alt="logo" />
      </div>
      <div class="mobile-close-btn" (click)="showMobileList = !showMobileList">
        <span>X</span>
      </div>
    </section>

    <ng-select
      [(ngModel)]="selectedCity"
      placeholder="موقعك"
      class="location-dropdown-mob"
      name="location"
      (change)="updateCity($event)"
    >
      <ng-option *ngFor="let city of cities" [value]="city.id">
        <a style="color: #7e7e7e"> {{ city.name }}</a>
      </ng-option>
    </ng-select>

    <ul class="navbar-nav mobile-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/home']"
          routerLinkActive="active"
          (click)="showMobileList = !showMobileList"
          >{{ "Home" | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/offers', categories[0]?.id]"
          routerLinkActive="active"
          (click)="showMobileList = !showMobileList"
          >{{ "Houssomat Categories" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/subscriptions']"
          routerLinkActive="active"
          (click)="showMobileList = !showMobileList"
          >{{ "Subscription Packages" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/download']"
          routerLinkActive="active"
          (click)="showMobileList = !showMobileList"
          >{{ "App Shoots" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/contactus']"
          routerLinkActive="active"
          (click)="showMobileList = !showMobileList"
          >{{ "Contact Us" | translate }}</a
        >
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="nav-link" (click)="onLogout()">{{ "Logout" | translate }}</a>
      </li>
    </ul>

    <form class="mobile-search-form" role="search">
      <ng-select
        [(ngModel)]="selectedCategory"
        placeholder=" {{ 'Category' | translate }} "
        class="mob-categories-dropdown"
        name="test"
      >
        <ng-option *ngFor="let category of categories" [value]="category.id">{{
          category.name
        }}</ng-option>
      </ng-select>

      <input
        class="form-control"
        type="search"
        placeholder="{{ 'Search' | translate }}"
        aria-label="Search"
      />
      <button class="btn btn-outline-success mob-sub-btn" type="submit">
        {{ "Search" | translate }}
      </button>
    </form>

    <div class="social-link">
      <a href="https://www.facebook.com/7usoomat" class="link facebook">
        <img
          src="../../../assets/images/socials/facebook.png"
          alt="social-link"
        />
      </a>

      <a href="https://instagram.com/7usoomat" class="link insta">
        <img src="../../../assets/images/socials/insta.png" alt="social-link" />
      </a>

      <a href="https://twitter.com/7usoomat" class="link twitter">
        <img
          src="../../../assets/images/socials/twitter.png"
          alt="social-link"
        />
      </a>

      <a
        href="https://www.youtube.com/channel/UCxv1dqDrmq9ZtfdlTriFGJw"
        class="link youtube"
      >
        <img
          src="../../../assets/images/socials/youtube.png"
          alt="social-link"
        />
      </a>
    </div>
  </div>
</div>

<div
  class="top-header d-none d-lg-block"
  [ngClass]="{ en: currentLang == 'en' }"
>
  <div class="container">
    <ul class="nav top-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="showLang = !showLang">{{ countryName }}</a>
      </li>

      <ul class="lang-options" [ngClass]="{ show: showLang }">
        <li
          class="lang"
          *ngFor="let lang of listLang"
          (click)="setLanguage(lang.text, lang.lang, lang.flag)"
        >
          <img [src]="lang.flag" alt="flag" />
          {{ lang.text }}
        </li>
      </ul>

      <li class="nav-item">
        <a class="nav-link spliter" [routerLink]="['/stepstojoin']">
          {{ "Subscriptions Steps" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="nav-link spliter" (click)="onLogout()">
          {{ "Logout" | translate }}
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <div class="nav-link spliter">
          <span class="title">{{ "Current Package" | translate }} : </span>
          <span>{{ packageInfo?.package?.title }}</span>
        </div>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <div class="nav-link spliter">
          <span class="title">{{ "Available Coupons" | translate }} : </span>
          <span>{{ packageInfo?.unlimited_coupons }}</span>
        </div>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <div class="nav-link spliter">
          <span class="title">{{ "Expired Date" | translate }} : </span>
          <span>{{ packageInfo?.expired_at_format }}</span>
        </div>
      </li>
    </ul>

    <div class="phone">
      {{ "Contact Us" | translate }} :
      <a href="tel:966544664666" dir="ltr">+966544664666</a>
    </div>
  </div>
</div>

<div class="middle-header" [ngClass]="{ en: currentLang == 'en' }">
  <nav class="navbar navbar-expand-lg middle-nav">
    <div class="container">
      <!-- <a class="navbar-brand" href="#">
        <img src="../../../assets/images/logo.png" alt="logo" />
      </a> -->

      <form class="d-flex search-form d-none d-lg-flex">
        <ng-select
          [(ngModel)]="selectedCategory"
          placeholder="{{ 'Category' | translate }}"
          class="categories-dropdown-select"
          name="test"
        >
          <ng-option
            *ngFor="let category of categories"
            [value]="category.id"
            >{{ category.name }}</ng-option
          >
        </ng-select>
        <input
          class="form-control me-2 search-input"
          type="search"
          placeholder=" {{ 'Search' | translate }}..."
          aria-label="Search"
        />
        <button class="btn search-btn" type="submit">
          <img src="../../../assets/images/icons/search-icon.png" />
        </button>
      </form>

      <a class="navbar-brand" [routerLink]="['/home']">
        <img src="assets/images/logo.png" alt="logo" />
      </a>

      <ul class="nav middle-nav">
        <li class="nav-item location-dropdown d-none d-lg-block">
          <ng-select
            [(ngModel)]="selectedCity"
            placeholder="{{ 'Location' | translate }}"
            class="location-dropdown-select"
            name="location"
            (change)="updateCity($event)"
          >
            <ng-option *ngFor="let city of cities" [value]="city.id">
              <a style="color: #7e7e7e"> {{ city.name }}</a>
            </ng-option>
          </ng-select>
        </li>

        <li class="nav-item" *ngIf="!isAuthenticated">
          <button class="auth-button" (click)="onAuthenicate()">
            {{ "Login" | translate }}
          </button>
        </li>

        <li class="nav-item" *ngIf="isAuthenticated">
          <a class="nav-link d-none d-lg-block">
            {{ "Welcome" | translate }} {{ username }}
          </a>
        </li>

        <li
          class="nav-item d-block d-lg-none"
          (click)="showMobileList = !showMobileList"
        >
          <div class="mobile-nav-btn">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div
  class="bottom-header d-none d-lg-block"
  [ngClass]="{ en: currentLang == 'en' }"
>
  <nav style="background-color: white" class="navbar navbar-expand-lg">
    <div class="container">
      <div
        class="collapse navbar-collapse bottom-nav"
        id="navbarSupportedContent"
      >
        <div class="all-cat-sec">
          <div
            class="all-cat-btn"
            id="all-cat-btn"
            (click)="this.categoryShow = !this.categoryShow"
          >
            {{ "All Categories" | translate }}
          </div>

          <ul class="categories-sec" [ngClass]="{ visible: categoryShow }">
            <li
              class="cat-item"
              *ngFor="let cat of categories"
              (click)="this.categoryShow = false"
              [routerLink]="['/offers', cat.id]"
              routerLinkActive="active"
            >
              <a>
                <img [src]="cat.icon" [alt]="cat.name" />

                <span> {{ cat.name }} </span>
              </a>
            </li>
          </ul>
        </div>

        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/home']"
              routerLinkActive="active"
              >{{ "Home" | translate }}</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/offers', categories[1]?.id]"
              [queryParams]="{ page: '1' }"
              routerLinkActive="active"
            >
              {{ "Houssomat Categories" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/subscriptions']"
              routerLinkActive="active"
            >
              {{ "Subscription Packages" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/download']"
              routerLinkActive="active"
              >{{ "App Shoots" | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/contactus']"
              routerLinkActive="active"
            >
              {{ "Contact Us" | translate }}
            </a>
          </li>
        </ul>
        <div class="hotline">
          <img
            src="../../../assets/images/icons/icon-headphone.svg"
            alt="hotline"
          />
          <div class="hotline-text">
            <a dir="ltr" class="num" href="tel:92000881">92000881</a>
            <span class="text">24/7 Support Center</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
