import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-alert',
  templateUrl: './payment-alert.component.html',
  styleUrls: ['./payment-alert.component.css'],
})
export class PaymentAlertComponent implements OnInit {
  constructor(private activatedRouter: ActivatedRoute) {}
  paymentSuccess = 0;
  title = 'Payment Failed';
  description = 'Unfortunately Payment Failed You Can Try Again';
  ngOnInit(): void {
    this.paymentSuccess =
      this.activatedRouter.snapshot.queryParams['success'] || 0;
    this.title =
      this.activatedRouter.snapshot.queryParams['title'] || 'Payment Failed';
    this.description =
      this.activatedRouter.snapshot.queryParams['description'] ||
      'Unfortunately Payment Failed You Can Try Again';
  }
}
