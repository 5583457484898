<div class="container">
  <!-- <div class="allowed-card" *ngIf="paymentSuccess == 1">
    <img src="assets/images/icons/success-alert.svg" />
    <h3>{{ "Payment Done" | translate }}</h3>
    <p>{{ "Now You Can Enjoy Our Services" | translate }}</p>
  </div>
  <div class="denied-card" *ngIf="paymentSuccess == 0">
    <img src="assets/images/icons/error-alert.svg" />
    <h3>{{ "Payment Failed" | translate }}</h3>
    <p>{{ "Unfortunately Payment Failed You Can Try Again" | translate }}</p>
  </div>
   -->
  <div
    [ngClass]="{
      'allowed-card': paymentSuccess == 1,
      'denied-card': paymentSuccess == 0
    }"
  >
    <img
      src="assets/images/icons/error-alert.svg"
      *ngIf="paymentSuccess == 0"
    />
    <img
      src="assets/images/icons/success-alert.svg"
      *ngIf="paymentSuccess == 1"
    />
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
  </div>
</div>
