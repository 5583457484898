import * as crypto from 'crypto-js';
import { ActivationData } from './../../core/models/auth.model';
import { CitiesService } from 'src/app/core/services/cities/cities.service';
import { Component, OnInit } from '@angular/core';
import { LoginData, RegisterData } from 'src/app/core/models/auth.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NationalitiesService } from 'src/app/core/services/nationalities/nationalities.service';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/core/services/location/location.service';
import { LocalStorgeService } from 'src/app/core/services/user/local/local-update.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ProfileService } from 'src/app/core/services/user/profile/profile.service';
import { PROFILE } from 'src/app/core/models/profile.model';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnInit {
  showAuth = false;
  showVerfication = false;
  loginMood = true;
  showLoginError = false;
  activationWrog = false;
  showRegisterError = false;
  showEmailError = false;
  showProfileData = false;
  ProfileData: PROFILE;
  phoneErrorMessage = 'رقم الجوال مسجل من قبل.';
  loginData: LoginData = {};
  registerData: RegisterData = {};
  activationData: ActivationData = {};
  cities = [];
  nationalities = [];
  constructor(
    private citiesService: CitiesService,
    private authService: AuthService,
    private nationalitiesService: NationalitiesService,
    private locationService: LocationService,
    private localService: LocalStorgeService,
    private router: Router,
    private profileService: ProfileService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    window['dataLayer'] = window['dataLayer'] || {};
    this.authService.showAuth.subscribe((auth) => {
      if (auth) {
        this.getAllCities();
        this.getAllNationalities();
        this.showAuth = true;
      }
    });
  }

  onLogin() {
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
      this.loginData['g-recaptcha-response'] = token;
      this.showLoginError = false;
      this.authService.login(this.loginData).subscribe({
        next: () => {
          window['dataLayer'].push({
            '7us_phone': crypto.SHA1(`+966${this.loginData.field}`),
          });
          this.activationData = {};
          this.showVerfication = true;

          this.localService.updateLocalUserInfo(
            'city_id',
            this.loginData.city_id
          );
          this.locationService.updateCoordinates();
        },
        error: () => (this.showLoginError = true),
      });
    });
  }

  onTempRegister() {
    this.showRegisterError = false;
    this.showEmailError = false;
    this.activationWrog = false;
    this.authService
      .tempRegister({ phone: this.registerData.phone })
      .subscribe({
        next: (res) => (this.showVerfication = true),
        error: (error) => {
          this.showRegisterError = true;
          this.phoneErrorMessage = error.error.message;
        },
      });
  }

  onRegister() {
    this.authService.register(this.registerData).subscribe({
      next: (res) => {
        this.authService.setUser(res.data);
        this.authService.userAuthenicated.next(res.data);
        // this.authService.setToken(res.data.token);
        this.router.navigate(['/home']);
        this.showAuth = false;
        this.showVerfication = false;
      },
      error: (error) => {
        this.activationWrog = true;
        this.showVerfication = false;
        this.showEmailError = true;
      },
    });
  }

  getAllCities() {
    if (this.cities.length === 0) {
      this.citiesService
        .getAllCities()
        .subscribe((res: any) => (this.cities = res.data));
    }
  }

  getAllNationalities() {
    if (this.nationalities.length === 0) {
      this.nationalitiesService
        .getAllNationalities()
        .subscribe((res: any) => (this.nationalities = res.data));
    }
  }

  onCheckActivationCode(code: string) {
    if (code.length === 5) {
      this.activationWrog = false;
      this.activationData.phone = this.loginMood
        ? this.loginData.field
        : this.registerData.phone;
      this.activationData.new_user = this.loginMood ? 0 : 1;

      this.authService.checkActivation(this.activationData).subscribe({
        next: (res) => {
          if (this.loginMood) {
            this.authService.setUser(res.data);
            this.showAuth = false;
            this.authService.userAuthenicated.next(res.data);
            this.profileDataHandler(res.data.id);
            this.showVerfication = false;
          } else {
            this.onRegister();
          }
        },
        error: (error) => {
          this.activationWrog = true;
          this.activationData.code = null;
        },
      });
    }
  }

  profileDataHandler(userId) {
    this.profileService
      .getProfileData({ user_id: userId })
      .subscribe((profile: any) => {
        this.ProfileData = profile.data;
        this.showProfileData = true;
        setTimeout(() => (this.showProfileData = false), 3000);
      });
  }

  onCloseAuth() {
    this.showVerfication = false;
    this.loginMood = true;
    this.showLoginError = false;
    this.activationWrog = false;
    this.showRegisterError = false;
    this.showAuth = false;
    this.loginData = {};
    this.registerData = {};
    this.activationData = {};
  }
}
