import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'ar'];
  languageChanged = new BehaviorSubject<string>('ar');
  currentLang;
  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.getLanguage();
  }

  public setLanguage(lang) {
    if (this.currentLang !== lang) {
      this.translate.use(lang);
      this.cookieService.set('lang', lang);
      this.languageChanged.next(lang);
      this.changeDirection(lang);
      window.location.reload();
    }
  }

  public getLanguage() {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    } else {
      this.setLanguage('ar');
      browserLang = 'ar';
    }
    this.translate.use(browserLang.match(/en|ar/) ? browserLang : 'ar');
    this.changeDirection(browserLang);
    this.currentLang = browserLang;
  }

  changeDirection(lang) {
    const dir = lang == 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
    document.documentElement.setAttribute('lang', lang);
    this.languageChanged.next(lang);
  }
}
