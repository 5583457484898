<div
  *ngFor="let item of data; let i = index"
  [ngClass]="{ en: currenLang == 'en' }"
>
  <button class="accordion" (click)="toggleAccordian($event, i)">
    {{ item.parentName | translate }}
  </button>
  <div class="panel" hide="!item.isActive">
    <p *ngFor="let child of item.childProperties" class="pannel-item">
      {{ child.propertyName | translate }}
    </p>
  </div>
</div>
