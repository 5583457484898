import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cutLongString' })
export class cutStringsPipe implements PipeTransform {
  transform(value: string) {
    if (value.length > 50) {
      return `${value.substring(0, 50)}...`;
    } else {
      return value;
    }
  }
}
