import { LocalStorgeService } from 'src/app/core/services/user/local/local-update.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Payment } from 'src/app/core/models/payment.model';
import { PackagesService } from 'src/app/core/services/packages/packages.service';
import { PaymentService } from 'src/app/core/services/payment/payment.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';

declare var myFatoorah: any;
declare var myFatoorahAP: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  public paymentData: Payment;
  paymentSub: Subscription;
  today = new Date().toISOString().split('T')[0];
  userName = '';
  paymentPackageData;
  public confirmationURL = '';
  public paymentSuccess = false;
  constructor(
    private paymentService: PaymentService,
    private lsService: LocalStorgeService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paymentSub = this.paymentService.initialSessionDone.subscribe(
      (session) => {
        if (this.paymentData !== session) {
          if (session.payment_id == '1') {
            this.initPayment(session);
          } else {
            this.initApplePay(session);
          }
        }
      }
    );

    this.getUsername();

    this.paymentService.paymentSuccess.subscribe((url) => {
      this.paymentConfirmation(url);
    });
  }

  getUsername() {
    this.userName = this.lsService.getItem('userData')?.name;
  }

  // my fatorah pay with visa
  initPayment(sessionData) {
    this.paymentData = sessionData;
    var config = {
      countryCode: this.paymentData.countryCode, // Here, add your Country Code you receive from InitiateSession Endpoint.
      sessionId: this.paymentData.sessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
      cardViewId: 'card-element',
    };
    myFatoorah.init(config);
  }

  applyPayment() {
    const data = this.paymentData;
    const paymentService = this.paymentService;
    // const packagesService = this.packagesService;
    const alertService = this.alertService;
    // const router = this.router;
    myFatoorah.submit().then(
      function (response) {
        var sessionId = response.sessionId;
        var cardBrand = response.cardBrand;

        const body = {
          session_id: data.sessionId,
          pay_type: 'card',
          payment_id: 2,
          coupon_id: data.coupon_id || 0,
          coupon: data.coupon || 0,
          paid_price: data.total_amount,
          total_amount: data.total_amount,
          package_id: data.package_id,
          type: 'package',
        };

        paymentService.executePayment(body).subscribe((res) => {
          const voiceId = res.data.invoice_id;

          const packageData = {
            ...body,
            invoice_id: voiceId,
          };

          paymentService.paymentSuccess.next(res.data.url);
          // packagesService.packageSubscripe(packageData).subscribe((res) => {
          //   paymentService.paymentClosed.next(false);
          //   router.navigate(['home']);
          // });
        });
      },
      function (error) {
        alertService.showAlert.next({
          type: 'error',
          message: 'بيانات الدفع غير صحيحة',
        });
      }
    );
  }

  paymentConfirmation(confirmationURL) {
    this.confirmationURL = confirmationURL;
    this.paymentSuccess = true;
  }

  // my fatorah pay with apple pay

  initApplePay(sessionData) {
    this.paymentData = sessionData;

    var config = {
      countryCode: this.paymentData.countryCode, // Here, add your Country Code you receive from InitiateSession Endpoint.
      sessionId: this.paymentData.sessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
      currencyCode: 'SAR', // Here, add your Currency Code.
      amount: this.paymentData.total_amount, // Add the invoice amount.
      cardViewId: 'card-element',
      callback: this.payment.bind(this),
      sessionStarted: this.sessionStarted,
      sessionCanceled: this.sessionCanceled,
    };
    myFatoorahAP.init(config);
  }

  sessionStarted() {}

  sessionCanceled() {}

  payment(response) {
    var sessionId = response.sessionId;
    var cardBrand = response.cardBrand;
    const body = {
      session_id: this.paymentData.sessionId,
      pay_type: 'card',
      payment_id: 2,
      coupon_id: this.paymentData.coupon_id || 0,
      coupon: this.paymentData.coupon || 0,
      paid_price: this.paymentData.total_amount,
      total_amount: this.paymentData.total_amount,
      package_id: this.paymentData.package_id,
      type: 'package',
    };

    this.paymentExecution(body);
  }

  paymentExecution(body) {
    // this.paymentSuccess = true;
    this.paymentService.executePayment(body).subscribe((res) => {
      // this.confirmationURL = res.data.url;
      this.paymentService.paymentSuccess.next(res.data.url);
      const voiceId = res.data.invoice_id;
      this.paymentPackageData = {
        ...body,
        invoice_id: voiceId,
      };
    });
  }

  closePayment() {
    this.paymentService.paymentClosed.next(false);
  }

  donePayment() {
    this.closePayment();
    this.router.navigate(['/home'], { queryParams: { subDone: 1 } });
  }

  ngOnDestroy() {
    this.paymentSub.unsubscribe();
  }
}
