import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private http: HttpService) {}

  getAllCategories() {
    return this.http.getData('categories');
  }

  // getCategoryData(categoryId: number) {
  //   return this.http.get(`categories/${categoryId}`);
  // }
}
