import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NationalitiesService {
  constructor(private http: HttpService) {}

  getAllNationalities() {
    return this.http.getData('regions/nationalities', { return_all: 1 });
  }
}
