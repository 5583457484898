<div class="profile-data">
  <img src="assets/images/icons/success-alert.svg" alt="icon" />

  <h3 class="welcome-msg">
    {{ "Thank You" | translate }} {{ ProfileData.name }}
  </h3>
  <!-- <p class="success-login">تم تسجيل الدخول بنجاح</p> -->
  <div class="user-info">
    <div class="info-item">
      <span class="title">{{ "Current Package" }}</span>
      <span class="value">
        {{ ProfileData?.subscribed_package?.package?.title }}
      </span>
    </div>
    <div class="info-item">
      <span class="title">{{ "Available Coupons" | translate }}</span>
      <span class="value">
        {{ ProfileData?.subscribed_package?.unlimited_coupons }}
      </span>
    </div>
    <div class="info-item">
      <span class="title">{{ "Expired Date " | translate }}</span>
      <span class="value">
        {{ ProfileData?.subscribed_package?.expired_at_format }}
      </span>
    </div>
  </div>
</div>
