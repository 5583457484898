<div class="app-shots">
  <div class="container">
    <div class="sec-header">
      <div class="headers">
        <h2 class="title">{{ "Houssomat App Shoots" | translate }}</h2>
        <ul class="shots-nav">
          <li
            class="item"
            [ngClass]="{ selected: selectedItem === 'online' }"
            (click)="changeItems('online')"
          >
            {{ "Online Shopping" | translate }}
          </li>
          <li
            class="item"
            [ngClass]="{ selected: selectedItem === 'store' }"
            (click)="changeItems('store')"
          >
            {{ "Shopping In Store" | translate }}
          </li>
        </ul>
      </div>

      <div class="download-links">
        <a
          href="https://bit.ly/ios7usoomat"
          class="download-item"
          target="_blank"
        >
          <img
            src="assets/images/icons/apple-black.svg"
            alt="icons"
            class="icon"
          />
          <span>{{ "For Iphone" | translate }}</span>
        </a>
        <a
          href="https://bit.ly/and7usoomat"
          class="download-item"
          target="_blank"
        >
          <img
            src="assets/images/icons/google-play-black.svg"
            alt="icons"
            class="icon"
          />
          <span> {{ "For Android" | translate }} </span>
        </a>
        <a
          href="https://bit.ly/huw7usoomat"
          class="download-item"
          target="_blank"
        >
          <img
            src="assets/images/icons/huawei-black.svg"
            alt="icons"
            class="icon"
          />
          <span> {{ "For Huawei" | translate }} </span>
        </a>
      </div>
    </div>

    <div class="shots-container">
      <div class="shot-item" *ngFor="let shot of displayedShots">
        <img
          [defaultImage]="'assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="shot.img"
          alt="shot"
          class="shot"
        />
        <div class="shot-info">
          <h3 class="shot-title">{{ shot.title | translate }}</h3>
          <p class="shot-desciption">
            {{ shot.desc | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
