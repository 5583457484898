import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';
import { CitiesService } from 'src/app/core/services/cities/cities.service';
import { LocationService } from 'src/app/core/services/location/location.service';
import { LocalStorgeService } from 'src/app/core/services/user/local/local-update.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  cities = [];
  categories = [];
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  showLang = false;
  currentLang;
  categoryShow = false;
  showMobileList = false;
  selectedCity: number;
  selectedCategory: number;
  isAuthenticated = false;
  username = 'الملف الشخصى';
  packageInfo;

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    {
      text: 'العربية',
      flag: 'assets/images/flags/saudi-arabia.jpg',
      lang: 'ar',
    },
  ];

  constructor(
    private citiesService: CitiesService,
    private authService: AuthService,
    private locationService: LocationService,
    private localService: LocalStorgeService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private categoriesservice: CategoriesService
  ) {}

  ngOnInit(): void {
    if (this.categories.length == 0) {
      this.getAllCategories();
    }
    if (this.cities.length == 0) {
      this.getAllCities();
    }

    if (this.authService.isAuthenticated()) {
      this.isAuthenticated = true;
      this.username = this.authService.getUser().name;
    } else {
      this.isAuthenticated = false;
    }

    this.authService.userAuthenicated.subscribe({
      next: (userAuthData) => {
        if (userAuthData) {
          this.username = userAuthData.name;
          this.isAuthenticated = true;
        } else {
          this.isAuthenticated = false;
        }
      },
    });
    this.getProfiledata();
    this.getLanguage();

    this.languageService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );
  }

  getLanguage() {
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    localStorage.setItem('lang', this.cookieValue);
    if (this.cookieValue === 'en') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
      document.body.classList.add('en');
      document.body.classList.remove('ar');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      document.body.classList.add('ar');
      document.body.classList.remove('en');
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.showLang = false;
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    this._cookiesService.set('lang', lang);
    localStorage.setItem('lang', this.cookieValue);

    if (lang === 'en') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
      document.body.classList.add('en');
      document.body.classList.remove('ar');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      document.body.classList.add('ar');
      document.body.classList.remove('en');
    }
  }

  getProfiledata() {
    if (this.localService.isItemExist('userData')) {
      this.packageInfo =
        this.localService.getItem('userData').subscribed_package;
    }
  }

  getAllCategories() {
    this.categoriesservice.getAllCategories().subscribe((res: any) => {
      this.categories = res.data.data;
    });
  }

  getAllCities() {
    this.citiesService.getAllCities().subscribe((res: any) => {
      this.cities = res.data;
      this.cityInit();
    });
  }

  cityInit() {
    let userCity = this.localService.getLocalUserInfo();
    this.selectedCity = !userCity || !userCity.city_id ? 1 : userCity.city_id;
  }

  updateCity(event) {
    this.localService.updateLocalUserInfo('city_id', event);
    this.locationService.updateCoordinates();
    window.location.reload();
  }

  onAuthenicate() {
    this.authService.showAuth.next(true);
  }

  onLogout() {
    this.authService.logout();
    this.showMobileList = false;
  }
}
