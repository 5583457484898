import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  constructor(private languageService: LanguageService) {}
  currentLang;
  ngOnInit(): void {
    this.languageService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );
  }
}
