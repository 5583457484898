import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OfferDetails } from '../../models/offers.model';

import { StoresDetails, StoresOptions } from '../../models/stores.model';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  storeDetailsOpen = new Subject<StoresDetails>();
  offerDetailsOpen = new Subject<string>();
  constructor(private httpService: HttpService) {}

  getStores(options: StoresOptions, headers?) {
    return this.httpService.getData(`stores`, options);
  }

  getStoreDetails(storeId) {
    return this.httpService.getData(`stores/${storeId}`);
  }

  getSpecialOffers(offerUrl) {
    return this.httpService.getData(offerUrl);
  }
}
