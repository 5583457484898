<app-store-details></app-store-details>

<!-- starting ads section -->
<div
  class="disable-background"
  [ngClass]="{ show: showStartingAds || showProfileData }"
  (click)="onCloseAd()"
  style="z-index: 6666"
  *ngIf="homeDetails?.app_starting_ads"
></div>

<div
  class="ads-pop"
  [ngClass]="{ show: showStartingAds }"
  [ngStyle]="{
    'background-image':
      'url(' + homeDetails?.app_starting_ads?.web_image_ar + ')'
  }"
  *ngIf="homeDetails?.app_starting_ads"
  (click)="
    openAds(
      homeDetails?.app_starting_ads?.action,
      homeDetails?.app_starting_ads?.value,
      'starting app ads'
    )
  "
>
  <div class="ads-content">
    <div class="close-btn" (click)="onCloseAd()">x</div>

    <!-- <div class="ads-body">
      <div class="deal">
        <button
          class="btn shop-btn"
          *ngIf="homeDetails?.app_starting_ads?.action != 'none'"
          (click)="
            openAds(
              homeDetails?.app_starting_ads?.action,
              homeDetails?.app_starting_ads?.value
            )
          "
        >
          تصفح الأن
        </button>
      </div>
    </div> -->
  </div>
</div>

<div class="container">
  <!-- main big slider -->

  <section class="main-slider">
    <owl-carousel-o [options]="mainSliderOptions">
      <ng-template
        carouselSlide
        [id]="item.id"
        *ngFor="let item of mainSliderItems"
      >
        <img [src]="item.web_image" class="slider-img" alt="slider-item" />
      </ng-template>
    </owl-carousel-o>
  </section>

  <!-- catigories slider -->

  <section class="categories-sec">
    <div class="main-section-title">
      <h3>{{ "Best Categories" | translate }}</h3>
    </div>

    <div class="cat-slider">
      <owl-carousel-o [options]="categoriesSliderOptions">
        <ng-container>
          <ng-template
            carouselSlide
            *ngFor="let cat of categories"
            [id]="cat.id"
          >
            <div
              class="cat-slider-item"
              [ngStyle]="{ backgroundColor: cat.backgroundColor }"
              [routerLink]="['/offers', cat.id]"
            >
              <div class="card-img">
                <img [src]="cat.web_image" [alt]="cat.name" />
              </div>
              <div class="card-body">
                <h6 class="card-title">{{ cat.name }}</h6>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </section>

  <!-- <section class="banners">
    <div class="banners-container">
      <div class="banner-item" *ngFor="let adItem of homeDetails?.home_ads">
        <img
          [defaultImage]="'../../../assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="adItem.image"
          alt="homr ads"
        />

        <div class="banner-body">
          <button
            class="btn shop-btn"
            *ngIf="adItem?.action != 'none'"
            (click)="openAds(adItem?.action, adItem?.value)"
          >
            تصفح الأن
          </button>
        </div>
      </div>
    </div>
  </section> -->

  <section class="offers-sec" *ngFor="let offerSec of avalibleOffersSections">
    <div class="main-section-title">
      <h3>{{ offerSec.title }}</h3>
      <a
        [routerLink]="['/special-offers']"
        [queryParams]="{
          offersType: offerSec.title,
          offersUrl: offerSec.url,
          page: 1
        }"
        class="more-offers"
      >
        {{ "More" | translate }}
      </a>
    </div>

    <div class="offers-container">
      <div
        class="offer-item"
        *ngFor="let offerItem of offerSec.data"
        (click)="openOffer(offerItem.id)"
      >
        <img
          alt="offer-img"
          class="offer-img"
          [defaultImage]="'../../../assets/images/loading_store.svg'"
          [errorImage]="'../../../assets/images/loading_store.svg'"
          [useSrcset]="true"
          [lazyLoad]="offerItem.image"
        />
        <div class="offer-body">
          <div class="offer-counter">
            <app-count-down [endDate]="offerItem.end_at"></app-count-down>
          </div>
          <div class="offer-details">
            <h2 class="offer-name">
              {{ offerItem.title }}
            </h2>
            <p class="offer-vendor">
              {{ "By" | translate }}
              <span href="#" [id]="offerItem.store.id">
                {{ offerItem.store.name }}
              </span>
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <div class="offer-price" *ngIf="offerItem.type !== 'general'">
                {{ offerItem.price_after }} {{ "Rial" | translate }}
              </div>
              <div
                class="offer-price discount-amount"
                *ngIf="offerItem.type === 'general'"
              >
                {{ "Discount" | translate }} {{ offerItem.discount_value }} %
              </div>
              <div class="add-btn">
                <button class="btn">
                  <img
                    src="../../../assets/images/icons/icon-cart.svg"
                    alt="cart icon"
                    class="cart-icon"
                  />
                  <span> {{ "Redeem" | translate }} </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- home ad pop up -->
<div
  class="home-ad"
  *ngIf="showHomeAdPop"
  (click)="openAds(homeAd?.action, homeAd?.value, 'home ads')"
>
  <div class="ad-details">
    <div class="close-btn" (click)="showHomeAdPop = false">x</div>
    <img
      [defaultImage]="'../../../assets/images/loading_store.svg'"
      [useSrcset]="true"
      [lazyLoad]="homeAd.web_image_ar"
      alt="home ads"
    />

    <!-- <button
      class="btn browse-btn"
      *ngIf="homeAd?.action != 'none'"
      (click)="openAds(homeAd?.action, homeAd?.value)"
    >
      تصفح الأن
    </button> -->
  </div>
</div>

<app-user-profile
  [ProfileData]="ProfileData"
  *ngIf="showProfileData"
></app-user-profile>
