import { Subject } from 'rxjs';
import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  initialSessionDone = new Subject<any>();
  paymentClosed = new Subject<boolean>();
  paymentSuccess = new Subject<string>();

  constructor(private http: HttpService) {}

  initialSession() {
    return this.http.post('payment/get-session');
  }

  executePayment(paymentData) {
    return this.http.post('payment/execute-by-session', paymentData);
  }
}
