<div class="count-sec">
  <div class="count-item days">
    <div class="num">{{ days }}</div>
    <div class="unit">{{ "Day" | translate }}</div>
  </div>

  <div class="count-item hours">
    <div class="num">{{ hours }}</div>
    <div class="unit">{{ "Hour" | translate }}</div>
  </div>

  <div class="count-item mins">
    <div class="num">{{ mins }}</div>
    <div class="unit">{{ "Min" | translate }}</div>
  </div>

  <div class="count-item secs">
    <div class="num">{{ secs }}</div>
    <div class="unit">{{ "Sec" | translate }}</div>
  </div>
</div>
