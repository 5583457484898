import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class PackagesService {
  constructor(private http: HttpService) {}

  getAllPackages() {
    return this.http.getData('packages', { return_all: 1 });
  }

  getPreRequest(packageData) {
    return this.http.getData('packages/pre-request', packageData);
  }

  packageSubscripe(packageData) {
    return this.http.post('packages/subscribe', packageData);
  }
}
