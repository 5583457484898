import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.css'],
})
export class CountDownComponent implements OnInit {
  @Input() endDate!: string;
  countInterval: any;
  days = 0;
  hours = 0;
  mins = 0;
  secs = 0;

  constructor() {}
  ngOnInit(): void {
    const futureDate = new Date(this.endDate).getTime();
    this.countInterval = setInterval(() => {
      const Today = new Date().getTime();
      const duration = futureDate - Today;

      this.days = Math.floor(duration / (1000 * 60 * 60 * 24));

      this.hours = Math.floor(
        (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.mins = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
      this.secs = Math.floor((duration % (1000 * 60)) / 1000);

      if (duration <= 0) {
        clearInterval(this.countInterval);
      }
    }, 1000);
  }
}
