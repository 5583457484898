import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { StoresDetails } from 'src/app/core/models/stores.model';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { OffersService } from 'src/app/core/services/offers/offers.service';
import { StoresService } from 'src/app/core/services/stores/stores.service';

@Component({
  selector: 'app-store-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  @Output() storeClosed = new EventEmitter<boolean>();
  showOffer = false;
  showStore = false;
  currentLang;
  StoreData: StoresDetails;
  storeSubsciption: Subscription;
  offerSubsciption: Subscription;
  languageSubscription: Subscription;
  branches: any = [
    {
      parentName: 'Avalible Branches',
      childProperties: [],
    },
  ];

  constructor(
    private storesService: StoresService,
    private offerServices: OffersService,
    private langService: LanguageService
  ) {}

  ngOnInit(): void {
    this.storeSubsciption = this.storesService.storeDetailsOpen.subscribe(
      (StoreDetails: any) => {
        this.StoreData = StoreDetails;
        this.branches[0].childProperties = StoreDetails.branches;
        this.showStore = true;
      }
    );
    this.offerSubsciption = this.storesService.offerDetailsOpen.subscribe(
      (offerId: any) => this.openOffer(offerId)
    );

    this.languageSubscription = this.langService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );
  }

  openOffer(storeId) {
    this.offerServices.getOfferDetails(storeId).subscribe((res: any) => {
      this.offerServices.offerDetailsOpen.next(res.data);
      this.showStore = false;
      this.showOffer = true;
    });
  }

  toggleAccordian(event, index) {
    const element = event.target;

    element.classList.toggle('active');

    if (this.branches[index].isActive) {
      this.branches[index].isActive = false;
    } else {
      this.branches[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  closeStorePop() {
    this.showStore = false;
    this.showOffer = true;
    this.storeClosed.emit(true);
  }

  onOfferClose(offerClose) {
    if (offerClose) {
      if (this.StoreData) {
        this.showStore = true;
      }
      this.showOffer = false;
    }
  }

  onStoreClose(storeClose) {
    if (storeClose) {
      this.showStore = false;
      this.showOffer = true;
    }
  }

  ngOnDestroy(): void {
    this.storeSubsciption.unsubscribe();
    this.offerSubsciption.unsubscribe();
    this.languageSubscription.unsubscribe();
  }
}
