import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-to-join',
  templateUrl: './steps-to-join.component.html',
  styleUrls: ['./steps-to-join.component.css']
})
export class StepsToJoinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
