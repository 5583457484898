<app-store-details></app-store-details>
<div class="container">
  <!-- header -->
  <header class="page-header">
    <div class="row align-items-center">
      <div class="col-12 col-lg-4">
        <h2 class="page-title">{{ categoryName }}</h2>
        <div class="breadcrumb">
          <a [routerLink]="['/home']" class="link-item breadcrumb-item"
            >الصفحة الرئيسية</a
          >
          <span class="spliter">></span>
          <span class="breadcrumb-item">{{ categoryName }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-8 d-none d-lg-block">
        <ul class="tag-list">
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">الكل</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">ترفية</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">العناية بالسيارات</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">الكترونيات</span>
            </a>
          </li>
          <li>
            <a href="#" class="tag-item">
              <button type="button" class="tag-remove-btn">X</button>
              <span class="tag-name">مطاعم</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <div class="row">
    <div class="col-sm-12 col-lg-3">
      <div class="sidebar-list category-menu">
        <div class="list-title">الأقسام</div>
        <ul class="category-list">
          <li
            class="cat-item"
            *ngFor="let cat of categories"
            [routerLink]="['/offers/', cat.id]"
            routerLinkActive="cat-item-active"
          >
            <div class="cat-info">
              <img [src]="cat.icon" alt="cat-img" class="cat-img" />
              <span class="cat-name"> {{ cat.name }} </span>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="sidebar-list new-offers">
        <div class="list-title">العروض جديدة</div>
        <ul class="offers-list">
          <li class="offer-item" (click)="openOffer(1)">
            <img
              src="../../../assets/images/categories/resturant.png"
              alt="offer img"
              class="offer-img"
            />
            <div class="offer-body">
              <a href="#" class="offer-name"> قهوة اليوم بريال فقط</a>
              <span class="offer-price">25ريال</span>
            </div>
          </li>
          <li class="offer-item" (click)="openOffer(1)">
            <img
              src="../../../assets/images/categories/resturant.png"
              alt="offer img"
              class="offer-img"
            />
            <div class="offer-body">
              <a href="#" class="offer-name"
                >بيتزا وسط + (1 خبز الجبن مجاناً)</a
              >
              <span class="offer-price">25ريال</span>
            </div>
          </li>
          <li class="offer-item" (click)="openOffer(1)">
            <img
              src="../../../assets/images/categories/resturant.png"
              alt="offer img"
              class="offer-img"
            />
            <div class="offer-body">
              <a href="#" class="offer-name"> قهوة اليوم بريال فقط</a>
              <span class="offer-price">25ريال</span>
            </div>
          </li>
        </ul>
      </div> -->
    </div>

    <div class="col-sm-12 col-lg-9">
      <section class="offers-sec">
        <div class="offers-container">
          <div
            class="offer-item"
            (click)="openOffer(offer.id)"
            *ngFor="
              let offer of offers
                | paginate
                  : {
                      currentPage: currentPage,
                      itemsPerPage: itemsPerPage,
                      totalItems: totalItems
                    }
            "
          >
            <img [src]="offer.image" alt="offer-img" class="offer-img" />
            <div class="offer-body">
              <div class="offer-counter">
                <app-count-down [endDate]="offer.end_at"></app-count-down>
              </div>
              <div class="offer-details">
                <h2 class="offer-name">
                  {{ offer.title }}
                </h2>
                <p class="offer-vendor">
                  بواسطة <span> {{ offer.store_name }} </span>
                </p>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="offer-price" *ngIf="offer?.type !== 'general'">
                    {{ offer.price_after }} ريال
                  </div>

                  <div
                    class="offer-price discount-amount"
                    *ngIf="offer?.type === 'general'"
                  >
                    خصم {{ offer.discount_value }} %
                  </div>

                  <div class="add-btn">
                    <button class="btn">
                      <img
                        src="../../../assets/images/icons/icon-cart.svg"
                        alt="cart icon"
                        class="cart-icon"
                      />
                      <span>استبدال</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <paging-controls
          [isItemsPerPage]="false"
          (pageChange)="onPageChange($event)"
          maxSize="7"
        ></paging-controls>
      </section>
    </div>
  </div>
</div>
