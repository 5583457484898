import { Router } from '@angular/router';
import { LocalStorgeService } from 'src/app/core/services/user/local/local-update.service';
import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { USER } from '../../models/user.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userAuthenicated = new Subject<USER | null>();
  showAuth = new Subject<boolean>();

  constructor(
    private http: HttpService,
    private router: Router,
    private lsService: LocalStorgeService
  ) {}

  login(user) {
    return this.http.post('auth/v1-login', user);
    // return this.http.post('auth/login', user);
  }

  register(user) {
    return this.http.post('auth/register', user);
  }

  logout() {
    this.router.navigate(['/home']);
    this.userAuthenicated.next(null);
    this.lsService.deleteItem('userData');
  }

  tempRegister(phone) {
    return this.http.post('auth/temp-register', phone);
  }

  checkActivation(user) {
    return this.http.post('verification/confirm', user);
  }

  setUser(user) {
    this.lsService.setItem('userData', user);
  }

  getUser() {
    const user = this.lsService.getItem('userData');
    return user;
  }

  getToken() {
    if (this.lsService.isItemExist('userData')) {
      return this.getUser().token;
    } else {
      return null;
    }
  }

  isAuthenticated() {
    return this.getToken() ? true : false;
  }
}
