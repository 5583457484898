import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { Facility } from '../../models/facility.model';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesService {
  constructor(private http: HttpService) {}

  addFacility(facilityData: any) {
    return this.http.postFormData('join-requests', facilityData);
  }
}
