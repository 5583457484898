import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoaderService } from './../loader/loader.service';

import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    if (this.requests.length === 0) {
      this.loaderService.isLoading.next(false);
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req.clone({});

    this.requests.push(req);
    this.loaderService.isLoading.next(true);

    // if token exits will be added to request headers

    if (this.authService.getToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 200) {
              this.removeRequest(req);
            } else if (event.status === 403 || event.status === 401) {
              this.authService.logout();
              this.authService.userAuthenicated.next(null);
              this.authService.showAuth.next(true);
              this.removeRequest(req);
            }
          }
        },
        (err: any) => {
          this.removeRequest(req);

          if (err instanceof HttpErrorResponse) {
            if (err.error.message === 'Unauthenticated') {
              this.authService.logout();
              this.authService.userAuthenicated.next(null);
              this.authService.showAuth.next(true);
            }
            if (err.status == 403) {
              this.authService.logout();
              this.authService.userAuthenicated.next(null);
              this.authService.showAuth.next(true);
              this.removeRequest(req);
            }
          }
          this.removeRequest(req);

          return of(err);
        }
      )
    );
  }
}
