import { SpecialOfferOptions } from './../../core/models/offers.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffersService } from 'src/app/core/services/offers/offers.service';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';

@Component({
  selector: 'app-special-offers',
  templateUrl: './special-offers.component.html',
  styleUrls: ['./special-offers.component.css'],
})
export class SpecialOffersComponent implements OnInit {
  categoryId;
  offers;
  categories = [];
  categoryName;
  currentCategory;
  totalPages;
  itemsPerPage;
  currentPage;
  totalItems;
  OfferFilter: SpecialOfferOptions = {
    page: 1,
  };

  constructor(
    private route: ActivatedRoute,
    private offersService: OffersService,
    private router: Router,
    private categoriesService: CategoriesService
  ) {}

  ngOnInit(): void {
    if (this.categories.length == 0) {
      this.getAllCategories();
    }
    this.getQueryParams();
  }

  private getQueryParams(): void {
    this.route.queryParamMap.subscribe((qParams) => {
      const filters: SpecialOfferOptions = {
        page: 1,
      };
      qParams.keys.forEach((key) => {
        if (key === 'offersType' || key === 'page' || key === 'offersUrl') {
          (filters[key] as any) = qParams.get(key);
        } else {
          filters[key] = qParams.get(key);
        }
      });

      this.OfferFilter = filters;
      this.categoryName = this.OfferFilter.offersType;
      this.getOffers();
    });
  }

  getOffers() {
    this.offersService
      .getSpecialOffers(
        `${this.OfferFilter.offersUrl}&page=${this.OfferFilter.page}`
      )
      .subscribe((res: any) => {
        this.offers = res.data.data;
        this.totalPages = res.data.total_pages;
        this.itemsPerPage = res.data.per_page;
        this.currentPage = res.data.current_page;
        this.totalItems = res.data.total;
      });
  }

  getAllCategories() {
    this.categoriesService.getAllCategories().subscribe((res: any) => {
      this.categories = res.data.data;
    });
  }

  openOffer(offerId) {
    this.offersService.getOfferDetails(offerId).subscribe((res: any) => {
      this.offersService.offerDetailsOpen.next(res.data);
    });
  }

  onPageChange(page) {
    this.currentPage = +page;
    this.OfferFilter.page = page;
    this.router.navigate([], {
      queryParams: { page },
      queryParamsHandling: 'merge',
    });
  }
}
