<app-shoots></app-shoots>
<div class="join-sec">
  <div class="container">
    <div class="img d-none d-lg-block">
      <img src="../../../assets/images/join-img.png" alt="img" />
    </div>
    <div class="steps-sec">
      <h2 class="sec-title">
        {{ "Steps to subscribe to Houssomat" | translate }}
      </h2>

      <div class="sec-desc">
        {{
          "Discounts application is available on the Apple Store * Google Play * App Gallery Download the application now"
            | translate
        }}
      </div>

      <div class="steps-list">
        <div class="step">
          <img src="../../../assets/images/steps-to-join/2.png" alt="step" />
          <h3 class="step-title">
            {{ "Create a new subscription" | translate }}
          </h3>
          <p class="step-desc">
            {{
              "Subscribe with us - Register with your mobile number - Wait for the activation code"
                | translate
            }}
          </p>
        </div>
        <div class="step">
          <img src="../../../assets/images/steps-to-join/3.png" alt="step" />
          <h3 class="step-title">{{ "Subscription package" | translate }}</h3>
          <p class="step-desc">
            {{
              "Choose the fit subscription package for you from a range of packages and offers"
                | translate
            }}
          </p>
        </div>
        <div class="step">
          <img src="../../../assets/images/steps-to-join/1.png" alt="step" />
          <h3 class="step-title">
            {{ "Pay the subscription fee" | translate }}
          </h3>
          <p class="step-desc">
            {{
              "Choose the appropriate payment method for you, we provide you with multiple, easy and secure payment methods"
                | translate
            }}
          </p>
        </div>
        <div class="step">
          <img src="../../../assets/images/steps-to-join/4.png" alt="step" />
          <h3 class="step-title">{{ "God bless you" | translate }}</h3>
          <p class="step-desc">
            {{
              "Enjoy the strongest and best offers and discounts within the application from restaurants, cafes, medical centers, sports centers, beauty and spa, and the most important classifications that meet the needs of all family members"
                | translate
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
