<div class="join-sec">
  <div class="container">
    <h2 class="page-title">{{ "Add Your Store To Houssomat" | translate }}</h2>
    <p class="sub-title">
      {{
        "We are pleased to have you join us as a service provider from here"
          | translate
      }}
    </p>

    <a
      class="btn btn-success p-2 contract-download"
      href="assets/files/contract.pdf"
      target="_blank"
    >
      <span> {{ "Contract Download" | translate }} </span>
      <img src="assets/images/icons/download-icon.svg" alt="icon" />
    </a>

    <form class="join-form" (ngSubmit)="onSubmit()" #joinForm="ngForm">
      <div class="row">
        <div class="col-md-6 form-field">
          <label for="facility_name" class="col-form-label">
            {{ "Store Name" | translate }}
          </label>
          <input
            id="store_name"
            name="facility_name"
            type="text"
            class="form-control"
            required
            [(ngModel)]="facilityData.facility_name"
          />
        </div>
        <div class="col-md-6 form-field">
          <label for="facility_address" class="col-form-label">
            {{ "Store Address" | translate }}
          </label>
          <input
            id="facility_address"
            name="facility_address"
            type="text"
            class="form-control"
            required
            [(ngModel)]="facilityData.facility_address"
          />
        </div>
        <div class="col-md-6 form-field">
          <label for="administrator_name" class="col-form-label">
            {{ "Store Manager Name" | translate }}
          </label>
          <input
            id="administrator_name"
            name="administrator_name"
            type="text"
            class="form-control"
            required
            [(ngModel)]="facilityData.administrator_name"
          />
        </div>
        <div class="col-md-6 form-field">
          <label for="phone" class="col-form-label">{{
            "Phone" | translate
          }}</label>
          <input
            id="phone"
            name="phone"
            type="text"
            class="form-control"
            required
            [(ngModel)]="facilityData.phone"
          />
        </div>
        <div class="col-md-6 form-field">
          <label for="email" class="col-form-label">{{
            "Email" | translate
          }}</label>
          <input
            id="email"
            name="email"
            type="text"
            class="form-control"
            required
            [(ngModel)]="facilityData.email"
          />
        </div>
        <div class="col-md-6 form-field">
          <label for="contract" class="col-form-label">
            {{ "Add the contract here after signing" | translate }}
          </label>
          <input
            id="contract"
            name="contract"
            type="file"
            class="form-control"
            style="padding: 17px 20px"
            (change)="fileChange($event)"
            placeholder="Upload file"
            accept=".pdf"
            required
          />
        </div>
        <div class="col-12 form-field">
          <label for="details" class="col-form-label">
            {{ "Other details (optional)" | translate }}
          </label>
          <textarea
            class="form-control"
            name="details"
            id="details"
            [(ngModel)]="facilityData.extra_data"
          ></textarea>
        </div>
      </div>

      <button
        class="btn sub-btn"
        type="submit"
        [disabled]="joinForm.invalid || isFormValid()"
      >
        {{ "Send a request to join" | translate }}
      </button>
    </form>
  </div>
</div>
