<div class="disable-background" [ngClass]="{ show: true }"></div>
<div class="paymentPop" *ngIf="!paymentSuccess">
  <img src="assets/images/payment-logo-hossomat.svg" alt="logo" class="logo" />
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closePayment()"
  ></button>
  <h3 class="mb-4 text-center">{{ "Invoice" | translate }}</h3>
  <p class="payment-details">
    <span class="name">{{ "Total amount required" | translate }} : </span>
    <span> {{ paymentData?.total_amount }} {{ "Rial" | translate }} </span>
  </p>
  <p class="payment-details">
    <span class="name">{{ "Invoice Submitted To" | translate }} : </span>
    <span>
      {{ userName }}
    </span>
  </p>
  <p class="payment-details">
    <span class="name"> {{ "Date" | translate }} : </span>

    <span>
      {{ today }}
    </span>
  </p>

  <div id="card-element"></div>

  <button
    class="btn btn-primary w-100"
    style="height: 50px"
    *ngIf="paymentData?.payment_id == 1"
    (click)="applyPayment()"
  >
    {{ "Pay" | translate }}
  </button>
</div>

<div class="package-success" *ngIf="paymentSuccess">
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closePayment()"
  ></button>
  <!-- <img src="../../../assets/images/icons/success-alert.svg" alt="success" /> -->
  <h3>{{ "Payment Confirmation" | translate }}</h3>
  <p>{{ "Please Confirm Your Payment" | translate }}</p>

  <a
    [href]="confirmationURL"
    target="_blank"
    class="btn btn-primary"
    (click)="donePayment()"
  >
    {{ "Confirm" | translate }}
  </a>
</div>
