<app-payment *ngIf="showPayment"></app-payment>

<div class="page-header">
  <div class="container">
    <h2 class="main-title">
      {{ "Houssomat Your Way To Save Money" | translate }}
    </h2>
    <p class="sub-title">{{ "We Guarantee Unlimited Savings" | translate }}</p>
    <div class="nav-btns">
      <button class="download-btn">{{ "Download App" | translate }}</button>
      <button class="shop-btn" [routerLink]="['/offers/1']">
        {{ "Shop Now" | translate }}
      </button>
    </div>
    <div class="qr-code">
      <img src="assets/images/downloadQR.svg" alt="qr-code" class="qr-img" />
      <p class="text">
        {{ "Download the app to get the best savings offers" | translate }}
      </p>
    </div>
  </div>
</div>
<div class="container">
  <div class="packages-sec">
    <h2 class="sec-title">{{ "Houssomat Packages" | translate }}</h2>
    <div class="packages">
      <div
        class="package-item"
        *ngFor="let package of packages"
        (click)="getPreRequestData(package.id, package.title, '')"
        [ngClass]="{ selected: package.title === selectedPackage.name }"
      >
        <h3 class="package-name">{{ package.title }}</h3>
        <div class="package-details">
          <div class="coupons">
            <h4 class="text">{{ "Number Of Coupons" | translate }}</h4>
            <p class="value">{{ package.coupons_count }}</p>
          </div>

          <div class="duration">
            <h4 class="text">{{ "Duration" | translate }}</h4>
            <p class="value">
              <span *ngIf="package.date_count > 1">
                {{ package.date_count }}
              </span>
              {{ package.date_type }}
            </p>
          </div>
        </div>
        <div class="price">{{ package.price }} {{ "Rial" | translate }}</div>

        <button class="sub-btn">{{ "Subscribe" | translate }}</button>
      </div>
    </div>
  </div>

  <div>
    <div class="subscribe-details my-4" *ngIf="showPreRequest">
      <h2 class="main-title">
        {{ "Subscription Data" | translate }} ({{ selectedPackage.name }})
      </h2>

      <div class="details-container">
        <form
          class="payment-form"
          (ngSubmit)="onPayment()"
          #paymentForm="ngForm"
        >
          <div class="details-cards">
            <div class="price-details details-card">
              <h3 class="title">{{ "Payment Details" | translate }}</h3>
              <div
                class="price-item"
                *ngFor="let price of preRequestData?.prices"
              >
                <span class="text"> {{ price.title }} </span>
                <span class="value">
                  {{ price.value }} {{ "Rial" | translate }}</span
                >
              </div>

              <h3 class="title mt-4">{{ "Discount Coupon" | translate }}</h3>
              <div class="d-flex">
                <input
                  class="form-control coupon-input"
                  type="text"
                  placeholder=" {{ 'Coupon' | translate }} "
                  name="coupon"
                  [(ngModel)]="selectedCoupon"
                  [ngClass]="{ wrong: wrongCoupon }"
                />

                <button
                  class="btn btn-primary mx-2"
                  type="button"
                  (click)="getPreRequestDataWithCopon(selectedCoupon)"
                >
                  {{ "Activate" | translate }}
                </button>
              </div>
              <p *ngIf="wrongCoupon" class="text-danger">
                {{ "Coupon Is Incorrect" | translate }}
              </p>
            </div>

            <div class="payment-methods details-card">
              <h3 class="title">{{ "Payment Method" | translate }}</h3>

              <div class="payment-methods">
                <label
                  *ngFor="let method of paymentMethods"
                  class="payment-method"
                >
                  <div *ngIf="method.macSupport">
                    <input
                      type="radio"
                      name="payment"
                      [(ngModel)]="paymentData.payment_id"
                      [value]="method.id"
                      required
                      [disabled]="disabledPaymentsMethods"
                    />

                    <img [src]="method.icon" class="payment-img" />
                    <span class="method-name">
                      {{ method.title | translate }}
                    </span>
                  </div>
                </label>

                <label class="payment-method">
                  <input
                    type="checkbox"
                    id="voucher_code"
                    name="voucher_code"
                    class="form-radio-input"
                    [checked]="voucherChecked"
                    (change)="onVoucherCheckChange($event)"
                  />

                  <span class="method-name">
                    {{ "Activation Code" | translate }}
                  </span>
                </label>

                <div class="voucher-block" *ngIf="showVoucherInput">
                  <input
                    class="form-control me-2"
                    type="text"
                    placeholder=" {{ 'Activation Code' | translate }} "
                    name="coupon"
                    [(ngModel)]="paymentData.voucher_code"
                    [ngClass]="{ wrong: wrongVoucher }"
                  />
                  <p *ngIf="wrongVoucher" class="text-danger">
                    {{ "Invalid activation code" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary submit-btn"
            type="submit"
            [disabled]="paymentForm.invalid"
          >
            {{ "Pay" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="faq-sec">
    <h2 class="main-title">{{ "FAQ" | translate }}</h2>
    <p class="sub-title">
      {{ "Have questions? Here are our answers." | translate }}
    </p>
    <div class="questions-container">
      <app-accordation [accordData]="questions"></app-accordation>
    </div>
  </div>
</div>
