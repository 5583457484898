import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxTablePaginationModule } from 'ngx-table-pagination';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HomeComponent } from './pages/home/home.component';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { CountDownComponent } from './shared/count-down/count-down.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { OffersComponent } from './pages/offers/offers.component';
import { OfferPopupComponent } from './pages/offer-popup/offer-popup.component';
import { AppAccordationComponent } from './shared/app-accordation/app-accordation.component';
import { SpecialOffersComponent } from './pages/special-offers/special-offers.component';
import { LoadSpinnerComponent } from './shared/load-spinner/load-spinner.component';
import { Interceptor } from './core/services/interceptors/interceptor';
import { StoresComponent } from './pages/stores/stores.component';
import { cutStringsPipe } from './shared/pipes/short-string.pipe';
import { DetailsComponent } from './pages/stores/details/details.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { StepsToJoinComponent } from './pages/steps-to-join/steps-to-join.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { ShootsComponent } from './pages/shoots/shoots.component';
import { SafePipe } from './core/pipes/santizer.pipe';
import { AuthComponent } from './pages/auth/auth.component';
import { JoinUsComponent } from './pages/join-us/join-us.component';
import { AlertComponent } from './shared/alert/alert.component';
import { PaymentComponent } from './shared/payment/payment.component';
import { UserProfileComponent } from './shared/user-profile/user-profile.component';
import { PaymentAlertComponent } from './pages/payment-alert/payment-alert.component';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CountDownComponent,
    FooterComponent,
    OffersComponent,
    SpecialOffersComponent,
    OfferPopupComponent,
    AppAccordationComponent,
    LoadSpinnerComponent,
    StoresComponent,
    cutStringsPipe,
    DetailsComponent,
    PoliciesComponent,
    MembershipComponent,
    TermsComponent,
    AboutusComponent,
    ContactusComponent,
    StepsToJoinComponent,
    SubscriptionsComponent,
    ShootsComponent,
    SafePipe,
    AuthComponent,
    JoinUsComponent,
    AlertComponent,
    PaymentComponent,
    UserProfileComponent,
    PaymentAlertComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LazyLoadImageModule,
    NgSelectModule,
    BrowserAnimationsModule,
    CarouselModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    NgxTablePaginationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LeiWIgiAAAAALAdSkQlEQwW6y1lt5k7hCPsedsz',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
