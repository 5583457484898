import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PROFILE } from 'src/app/core/models/profile.model';
import { HttpService } from '../../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  showProfileInfo = new Subject<PROFILE>();

  constructor(private http: HttpService) {}

  getProfileData(userId) {
    return this.http.getData('users/profile', userId);
  }
}
