import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(private titleService: Title, private metaService: Meta) {}

  updateBrowserTitle(currentTitle) {
    this.titleService.setTitle(`حسومات | 7usoomat ${currentTitle}`);
    this.metaService.updateTag({
      name: 'title',
      content: `حسومات | 7usoomat ${currentTitle}`,
    });
  }
}
