import { OfferDetails } from './../../core/models/offers.model';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OffersService } from 'src/app/core/services/offers/offers.service';
import { StoresService } from 'src/app/core/services/stores/stores.service';
import { StoresDetails } from 'src/app/core/models/stores.model';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-offer-popup',
  templateUrl: './offer-popup.component.html',
  styleUrls: ['./offer-popup.component.css'],
})
export class OfferPopupComponent implements OnInit, OnDestroy {
  @Output() offerClosed = new EventEmitter<boolean>();
  @Output() storeClosed = new EventEmitter<boolean>();
  offerInfo: OfferDetails;
  StoreData: StoresDetails;
  showOffer = false;
  showStore = false;
  currentUrl;
  currentLang;
  shareLink = 'https://web.7usoomat.com.sa';
  offerSubsciption: Subscription;
  branches: any = [
    {
      parentName: 'Available Branches',
      childProperties: [],
    },
  ];
  constructor(
    private offerService: OffersService,
    private router: Router,
    private langService: LanguageService,
    private storesService: StoresService
  ) {}

  ngOnInit(): void {
    this.offerSubsciption = this.offerService.offerDetailsOpen.subscribe(
      (offerData) => {
        this.offerInfo = offerData;
        this.branches[0].childProperties = offerData.branches;
        this.showOffer = true;
        this.shareLink = `https://web.7usoomat.com.sa/home/${this.offerInfo.offers.id}`;
      }
    );

    this.currentUrl = this.router.url;
    this.langService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );
  }

  toggleAccordian(event, index) {
    const element = event.target;

    element.classList.toggle('active');

    if (this.branches[index].isActive) {
      this.branches[index].isActive = false;
    } else {
      this.branches[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  closeOfferPop() {
    this.showOffer = false;
    this.branches[0].childProperties = [];
    this.offerInfo = { offers: {} };
    this.offerClosed.emit(true);
    this.storeClosed.emit(false);
  }

  // openStore(storeId) {
  //   this.storesService.getStoreDetails(storeId).subscribe((res: any) => {
  //     this.StoreData = res.data;
  //     this.showStore = true;
  //     this.showOffer = false;
  //   });
  // }

  openStore(storeId) {
    this.storesService.getStoreDetails(storeId).subscribe((res: any) => {
      this.storesService.storeDetailsOpen.next(res.data);
      this.showOffer = false;
    });
  }

  openOffer(offerId) {
    this.offerService.getOfferDetails(offerId).subscribe((res: any) => {
      this.offerInfo = res.data;
      this.showStore = false;
      this.showOffer = true;
    });
  }

  closeStorePop() {
    this.showStore = false;
    this.showOffer = true;
    this.storeClosed.emit(true);
    this.offerClosed.emit(false);
  }

  closeBackground() {
    this.showOffer = false;
    this.showStore = false;
  }

  ngOnDestroy(): void {
    this.offerSubsciption.unsubscribe();
  }
}
