<div
  class="disable-background"
  (click)="closeStorePop()"
  [ngClass]="{ show: showStore }"
></div>

<app-offer-popup
  [ngClass]="{ show: showOffer }"
  (offerClosed)="onOfferClose($event)"
  (storeClosed)="onStoreClose($event)"
></app-offer-popup>

<section class="store-details" [ngClass]="{ show: showStore }">
  <button type="button" class="btn-close" (click)="closeStorePop()"></button>

  <div class="details-conatiner" [ngClass]="{ en: currentLang == 'en' }">
    <div class="store-info">
      <div
        class="store-img"
        [ngStyle]="{ 'background-image': 'url(' + StoreData?.image + ')' }"
      ></div>

      <div class="store-data">
        <h3 class="store-name">{{ StoreData?.name }}</h3>

        <div class="avalibility-info">
          <span class="active" *ngIf="StoreData?.available === 1">
            {{ "Open" | translate }}
          </span>
          <span class="deactive" *ngIf="StoreData?.available !== 1">
            {{ "Closed" | translate }}
          </span>
          <div class="open-time">
            <img
              src="../../../../assets/images/icons/i-watch.svg"
              alt="clock icon"
            />
            <span class="time" dir="ltr">
              {{ StoreData?.end_time }} - {{ StoreData?.start_time }}
            </span>
          </div>
        </div>

        <div class="description">{{ StoreData?.description }}</div>

        <ul class="store-services" *ngIf="StoreData?.services.length !== 0">
          <li class="service" *ngFor="let service of StoreData?.services">
            <img [src]="service.image" alt="service image" />
            <p class="text">{{ service.name }}</p>
          </li>
        </ul>

        <div class="store-address">
          <img
            src="../../../../assets/images/icons/i-loco.svg"
            alt="address"
            class="phone-icon"
          />

          <a
            href="http://maps.google.com/maps?daddr={{
              StoreData?.main_branch?.lat
            }},{{ StoreData?.main_branch?.lng }}"
            class="address"
            target="_blank"
          >
            {{ StoreData?.main_branch?.address }}
          </a>
        </div>

        <div class="communication-info">
          <div class="phone">
            <img
              src="../../../../assets/images/icons/call-svg.svg"
              alt="location"
              class="phone-icon"
            />

            <span class="numbers" dir="ltr"> {{ StoreData?.phone }} </span>
          </div>

          <div class="social-links">
            <a
              [href]="StoreData?.social?.facebook"
              class="social-item"
              *ngIf="StoreData?.social?.facebook"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-face.svg"
                alt="facebook-link"
              />
            </a>
            <a
              [href]="StoreData?.social?.instagram"
              class="social-item"
              *ngIf="StoreData?.social?.instagram"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-insta.svg"
                alt="instegram-link"
              />
            </a>
            <a
              [href]="StoreData?.social?.snapchat"
              class="social-item"
              *ngIf="StoreData?.social?.snapchat"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-snap.svg"
                alt="snapchat-link"
              />
            </a>
            <a
              [href]="StoreData?.social?.tweeter"
              class="social-item"
              *ngIf="StoreData?.social?.tweeter"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-twitter.svg"
                alt="twitter-link"
              />
            </a>
            <a
              [href]="StoreData?.social?.website"
              class="social-item"
              *ngIf="StoreData?.social?.website"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-web.svg"
                alt="website-link"
              />
            </a>
            <a
              [href]="StoreData?.social?.whatsapp"
              class="social-item"
              *ngIf="StoreData?.social?.whatsapp"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-whats.svg"
                alt="whatsapp-link"
              />
            </a>
            <a
              [href]="StoreData?.social?.youtube"
              class="social-item"
              *ngIf="StoreData?.social?.youtube"
              target="_blank"
            >
              <img
                src="../../../../assets/images/icons/i-youtube.svg"
                alt="youtube-link"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="store-offers">
      <h3 class="avalible-title">{{ "Available Offers" | translate }}</h3>
      <div class="offers-container">
        <div
          class="offer-item"
          (click)="openOffer(offer.id)"
          *ngFor="let offer of StoreData?.offers"
        >
          <img
            [defaultImage]="'../../../../assets/images/loading_store.svg'"
            [errorImage]="'../../../../assets/images/error-img.svg'"
            [useSrcset]="true"
            [lazyLoad]="offer.image"
            alt="offer-img"
            class="offer-img"
          />
          <div class="offer-body">
            <div class="offer-counter">
              <app-count-down [endDate]="offer.end_at"></app-count-down>
            </div>
            <div class="offer-details">
              <h2 class="offer-name">
                {{ offer.title }}
              </h2>
              <div class="d-flex justify-content-between align-items-center">
                <div class="offer-price" *ngIf="offer?.type !== 'general'">
                  {{ offer.price_after }} {{ "Rial" | translate }}
                </div>

                <div
                  class="offer-price discount-amount"
                  *ngIf="offer?.type === 'general'"
                >
                  {{ "Discount" | translate }} {{ offer.discount_value }} %
                </div>

                <div class="add-btn">
                  <button class="btn">
                    <img
                      src="../../../assets/images/icons/icon-cart.svg"
                      alt="cart icon"
                      class="cart-icon"
                    />
                    <span>{{ "Redeem" | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="avalible-branches mt-4">
      <div *ngFor="let item of branches; let i = index">
        <button class="branches-accordion" (click)="toggleAccordian($event, i)">
          {{ item.parentName | translate }}
        </button>
        <div class="panel" hide="!item.isActive">
          <div *ngFor="let child of item?.childProperties" class="pannel-item">
            <div class="branch">
              <div class="branch-info">
                <h6 class="branch-name">{{ child.name }}</h6>
                <p class="branch-address">
                  {{ child.address }}
                </p>
                <div class="branch-phone">
                  <img
                    src="../../../assets/images/icons/ic_call_red.svg"
                    alt=""
                  />
                  {{ child.phone }}
                </div>
              </div>

              <a
                href="http://maps.google.com/maps?daddr={{ child.lat }},{{
                  child.lng
                }}"
                class="branch-location"
                target="_blank"
              >
                <img
                  src="../../../assets/images/icons/location-yellow.svg"
                  alt="branch location"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
