import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shoots',
  templateUrl: './shoots.component.html',
  styleUrls: ['./shoots.component.css'],
})
export class ShootsComponent implements OnInit {
  selectedItem = 'online';

  onlineShots = [
    {
      img: 'assets/images/app-shots/online2.png',
      title: 'Register with us for discounts',
      desc: 'Enter your mobile number and select the current city to filter the closest offers to you',
    },
    {
      img: 'assets/images/app-shots/online3.png',
      title: 'Receive the confirmation code',
      desc: 'You will receive a confirmation code and then proceed to the subscriptions or complete the registration steps',
    },
    {
      img: 'assets/images/app-shots/online1.png',
      title: 'Choose The Fit Package',
      desc: 'You will receive a confirmation code and then proceed to the subscriptions or complete the registration steps',
    },
  ];

  storeShots = [
    {
      img: 'assets/images/app-shots/store1.png',
      title: 'Home',
      desc: 'Shows you our special sections and special offers specially selected for you',
    },
    {
      img: 'assets/images/app-shots/store2.png',
      title: 'Side Menu',
      desc: 'Shortcuts for all the contents of the application for ease of use and navigation',
    },
    {
      img: 'assets/images/app-shots/store3.png',
      title: 'Category',
      desc: 'Show offers and stores together with the ability to search and filter',
    },
  ];

  displayedShots = this.onlineShots;

  constructor() {}

  ngOnInit(): void {}

  changeItems(item) {
    this.selectedItem = item;
    this.displayedShots =
      item === 'online' ? this.onlineShots : this.storeShots;
  }
}
