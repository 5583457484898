<div class="membership-sec">
  <div class="container">
    <div class="ar">
      <h2 class="page-title">التأهل للعضوية</h2>

      <p class="membership-text">
        التأهل للعضوية 1. تمنح عضوية الموقع فقط لمن تجازوت أعمارهم 18 عام. و
        للموقع الحق بإلغاءحساب أي عضو لم يبلغ الـ 18 عام دون إشعار مع الإلتزام
        بتصفية حساباته المالية فور إغلاق الحساب. 2. لا يحق لأي شخص إستخدام
        الموقع إذا ألغيت عضويته من قبل حسومات . 3. في حال قيام أي مستخدم
        بالتسجيل كمؤسسة تجارية، فإن مؤسسته التجارية تكون ملزمة بكافة الشروط
        والاحكام الواردة في هذه الإتفاقية. 4. ينبغي على كل شخص يستخدم الموقع
        الالتزام ( بكافة الانظمة القوانين المعمول بها لتنظيم التجارة الالكترونية
        ( التجارة عن طريق الانترنت). 5. لا يحق لأي عميل أو مؤسسة إستخدام بريد
        الكتروني واحد و/او رقم جوال واحد لفتح اكثر من حساب لأي سبب كان، و يحق
        لإدارة الموقع تجميد الحسابات المخالفة و/ أو إلغاء هذه الحسابات أو احدها
        مع الإلتزام بتصفية كافة العمليات المتعلقة بالحساب قبل إغلاقه و/او تجميده
        و/ أو الغائه دون ان يترتب اية مسؤولية على الموقع وحسومات. 6. يقر ويوافق
        العميل وفي حالة ثبوت مخالفته للبند (5) أعلاه بأنه يحق للموقع حسومات
        إلغاء اي طلبات شراء أو بيع أو اي عمليات تمت من قبل العميل و/او المؤسسة
        وفي هذه الحالة يقر ويوافق العميل و/او المؤسسة بأنه لا يترتب أية مسؤولية
        على موقع حسومات. 7. على المستخدمين ألافراد و المؤسسات الإلتزام بالعقود
        التجارية المبرمة مع الأعضاء. 8. من المعلوم والمتفق عليه صراحةً وضمناً
        بأنه لا يحق لأي عضو و/او عميل أن يستخدم خدمات الموقع (سواء كان ذلك العضو
        شخص طبيعي أو شخص معنوي) والقيام بعمليات بيع أو شراء منتجات ممنوعة أو
        مشبوهة أو مسروقة أو اية منتجات تعتبر مخالفة للأنظمة والقوانين المعمول
        بها في المملكة لعربية السعودية واية جهات حكومية أخرى تملك صلاحية الاشراف
        ومراقبة خدمات التجارة المحلية و أنظمة وأحكام التجارة الإلكترونية ، وفي
        حال ثبوت ذلك فإن العضو و/او العميل يضع نفسه ضمن طائلة المسؤولية
        القانونية والشخصية ويقر بتحمله المسؤولية القانونية كاملة كما يقر بعدم
        ترتب اي مسؤولية قانونية على موقع حسومات. 9. يقر العضو و/او العميل بأنه
        في حالة ثبوت مخالفته للبند رقم (7/1) بأنه يحق لحسومات اتخاذ كافة
        الاجراءات القانونية بمواجهته ومطالبته بالاضرار التي لحقت بها.
      </p>
    </div>

    <div class="en">
      <h2 class="page-title">Eligibility for Membership</h2>

      <p class="polcies-text">
        7usoomat.com wishes to ensure that its members are able to form legally
        binding contracts and further that minors do not purchase unsuitable
        content. Therefore, membership of the Site is not available to persons
        under the age of 18 years. You represent that you are 18 years of age or
        over the age of 18 years before you become a member of the Site. Without
        prejudice to any other rights and remedies of 7usoomat.com under this
        User Agreement or at law, 7usoomat.com reserves the right to limit or
        withdraw access to the Site or the membership of any person if
        7usoomat.com believes that person is under the age of 18 years. The Site
        is not available to persons whose membership has been suspended or
        withdrawn by 7usoomat.com. Further, if you are registering as a business
        entity, you represent that you have the authority to bind that entity to
        this User Agreement and that you and the business entity will comply
        with all applicable laws relating to online trading. No person or
        business entity may register as a member of the Site more than once
      </p>
    </div>
  </div>
</div>
