<div class="contact-us" [ngClass]="{ en: currentLang == 'en' }">
  <div class="container">
    <h2 class="page-title">{{ "Contact Us" | translate }}</h2>

    <div class="phone">
      <p class="text">{{ "To communicate through WhatsApp" | translate }}</p>
      <p class="num">+966558185925</p>
    </div>

    <div class="phone">
      <p class="text">{{ "For Direct Contact" | translate }}</p>
      <p class="num">92000881 & +966544664666</p>
    </div>

    <div class="email">
      <p class="text">{{ "Email" | translate }}</p>
      <p class="mail">customersupport@7usoomat.com</p>
    </div>

    <form class="contact-form" [formGroup]="contactForm">
      <div class="user-info">
        <input
          type="text"
          class="form-control form-input"
          placeholder="{{ 'Full Name' | translate }}"
          formControlName="name"
        />
        <input
          type="text"
          class="form-control form-input"
          placeholder="{{ 'Email' | translate }}"
          formControlName="email"
        />
        <input
          type="text"
          class="form-control form-input"
          placeholder="{{ 'Title' | translate }}"
          formControlName="title"
        />
        <input
          type="text"
          class="form-control form-input"
          placeholder="{{ 'Phone' | translate }}"
          formControlName="phone"
        />
      </div>

      <textarea
        name="message"
        class="form-control user-message"
        id="message"
        cols="30"
        rows="10"
        placeholder="{{ 'Your Message...' | translate }}"
        formControlName="message"
      ></textarea>

      <label class="form-check-label terms-check" for="gridCheck">
        <input
          name="gridCheck"
          value="I agree to the terms and privacy policy."
          class="form-check-input"
          type="checkbox"
          id="gridCheck"
          formControlName="checked"
          required
        />
        {{ "Accept the terms and conditions of the houssomat" | translate }}
      </label>

      <button
        type="submit"
        class="submit-btn"
        [disabled]="contactForm.invalid"
        (click)="onSubmit()"
      >
        {{ "Send Your Message" | translate }}
      </button>
    </form>
  </div>
</div>
