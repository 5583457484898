import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(private httpService: HttpService) {}

  getAllCities() {
    return this.httpService.getData('regions/cities', { return_all: 1 });
  }
}
