import { Injectable } from '@angular/core';
import { LocalStorgeService } from '../user/local/local-update.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private userLocalStorageService: LocalStorgeService) {}

  getLiveCoordinates(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateCoordinates() {
    this.getLiveCoordinates().then((res: any) => {
      this.userLocalStorageService.updateLocalUserInfo('coordinates', res);
    });
  }

  getLocalCoordinates() {
    const coord = this.userLocalStorageService.getLocalItem('coordinates');
    const defult = { lat: 0, lng: 0 };
    return coord ? coord : defult;
  }
}
