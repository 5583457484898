import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { StepsToJoinComponent } from './pages/steps-to-join/steps-to-join.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { OffersComponent } from './pages/offers/offers.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { SpecialOffersComponent } from './pages/special-offers/special-offers.component';
import { StoresComponent } from './pages/stores/stores.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ShootsComponent } from './pages/shoots/shoots.component';
import { JoinUsComponent } from './pages/join-us/join-us.component';
import { PaymentAlertComponent } from './pages/payment-alert/payment-alert.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'home/:offerId', component: HomeComponent },
  { path: 'offers', component: OffersComponent },
  { path: 'offers/:id', component: OffersComponent },
  { path: 'special-offers', component: SpecialOffersComponent },
  { path: 'stores', component: StoresComponent },
  { path: 'stores/:id', component: StoresComponent },
  { path: 'policies', component: PoliciesComponent },
  { path: 'membership', component: MembershipComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'stepstojoin', component: StepsToJoinComponent },
  { path: 'subscriptions', component: SubscriptionsComponent },
  { path: 'download', component: ShootsComponent },
  { path: 'joinUs', component: JoinUsComponent },
  { path: 'paymentAlert', component: PaymentAlertComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
