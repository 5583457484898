<div class="about-us">
  <div class="container">
    <div class="ar">
      <h2 class="page-title">من نحن</h2>

      <p style=font-weight:bold;font-size:20px;>مجموعة سعد عبدالمحسن الرصيص التجارية</p>
      <br />

      <p>
        حسومات هو عبارة عن تطبيق للهواتف الذكية لتسهيل شراء عروض بعدة خطوات
        بسيطة
      </p>
      <p>
        * يعطي التطبيق فرصة للعملاء بمواكبة احدث العروض و التطورات على الفور من
        خلال نظام اخباري على الاجهزة الذكية
      </p>
      <p>
        * موقع و تطبيق حسومات هو وجهتكم الاولى للحصول على فرص جديدة و خبرات
        هائلة بافضل العروض و الاسعار
      </p>
      <p>
        * موقع وتطبيق حسومات من المواقع الرائدة التي توفر افضل العروض في المملكة
        العربية السعودية
      </p>
      <p>
        * مهمتنا ان نبني مصداقية عالية لنكون وجهتكم الاولى للتسوق عبر الانترنت
        في جميع مناطق السعودية و نسعى لتقديم افضل العروض و الاسعار على الانترنت
        من خلال التعامل مع متاجر و مراكز محلية و وعالمية عن طريق تطبيق وموقع
        حسومات
      </p>
      <p>
        * موقع حسومات هو شركة تابعة لمجموعة سعد الرصيص متواجدون في &nbsp;الرياض
        . جدة . الدمام &nbsp;
      </p>
      <p>* التوفير هو جزء بسيط من ميزات تطبيق وموقع حسومات عند الاشتراك معنا</p>
      <p>
        * تطبيق وموقع حسومات يتيح لك الفرصة لاختبار تجارب جديدة و للتعرف على
        اماكن جديدة لقضاء اجمل لحظات حياتك بافضل الاسعار
      </p>
      <p>* مع موقع حسومات الربح دائما مضمون للعملاء و المراكز</p>
      <p>
        * عند وضع عروضكم على الموقع &nbsp;تحصلون على نتائج عالية من خلال تسويق
        مجاني و من دون أية &nbsp;مخاطر
      </p>
      <p>
        * حملات إعلانية مخصصة تعمل على تأمين قناة دخل جديدة لخدماتكم. علاوة على
        ذلك، سيزيد الحديث عن خدماتكم و مراكزكم عن طريق عروضكم التي ستنتشر عن
        طريق الايميل و مواقع التواصل الاجتماعي كـ فيسبوك. إنستقرام . تويتر
      </p>
      <p>
        * نحن نؤمن بتعزيز جودة الشركات المحلية بتقديم تجارب و نتائج ايجابية
        لافادة المجتمع. بالتالي يحصل شركائنا و عملائنا على قيمة مذهلة من خلالنا.
        بعض المعلومات عن طرق عملنا و نجاحنا :
      </p>

      <ul class="list">
        <li>
          نحن محليين و نشجع الخدمات المحلية • نقوم بعرض العروض و المنتجات ذو
          الجودة العالية
        </li>
        <li>
          اذا لم يكن العرض مغري، لن تراه على موقعنا، بكل بساطة • موقعنا غير
          محدود لبيع العروض نحن نقدم خبرات و تجارب جديدة لعملائنا باسعار مذهلة.
          اذا لم تكن راض عن اي عرض من عروضنا، لا تتردد بالتواصل معنا. لا يمكننا
          تحسين اختياراتنا و خدماتنا الا بتواصلك معنا. نحن لسنا الافضل، لكن نسعا
          لان نكون الافضل دائما.
        </li>
        <li>
          من اهم ميزاتنا الشفافية جميع الشروط و الاحكام لكل عرض ستكون واضحة
          لنتمكن من بناء صداقة و مصداقية عند تعاملكم معنا. انتم دائما مدعوون
          للمشاركة معنا على جميع مواقع التواصل الاجتماعي.
        </li>
        <li>
          لديك سؤال او تعليق؟ نسعى جاهدون بالرد عليك باسرع وقت ممكن نحن مثلك لا
          نحب التاخير او التأجيل.
        </li>
      </ul>

      <p>
        *موقع حسومات هو افضل طريقة لنمو شركتكم و خدماتكم : موقع حسومات يتيح لكم
        الفرصة للوصول الى اكبر جمهور ممكن لزيادة عملائكم دون اي تكلفة اضافية.
      </p>
      <p>
        * لا يوجد اي دفعات مسبقة عند وضع عروضكم عندنا. في الواقع، سيكون هناك
        ايرادات مع كل عرض مباع. و نحن سنقوم بتسديد قيمة العروض لديكم بالتزامن مع
        اعلان عروضكم و علاماتكم التجارية و خدماتكم على الموقع و مواقع التواصل
        الاجتماعي.
      </p>
      <p>
        * نحن نعلم اهمية العملاء الجدد لكم و مهمتنا عمل قناة جديدة تتيح للعملاء
        الجدد الوصول اليكم بداية من موقعنا.
      </p>
      <p>
        هذا يتم من خلال ارسال ممثل من موقع حسومات ليساعدك على تصميم العرض لحسب
        احتياجاتكم. ثم سنقوم باعلان عروضكم على موقعنا و مواقع التواصل الاجتماعي
        لتصل لآلاف العملاء المحتملين. عند شراء عروضكم، سيتم التعرف على عملاء جدد
        و النتيجة مثمرة لنا جميعا. الاعلان عن علامتكم التجارية: سيتم الاستفادة
        من جمهورنا النشط بعمل اعلانات مخصصة لعروضكم للتوافق مع اهدافكم
        التسويقية. موقع حسومات يقدم للمعلنين و الشركاء قناة مخصصة للتواصل مع
        عملائنا من خلال اعلانات مستهدفة. لدينا حلول للجميع بتوفير نشاطات اعلانية
        مناسبة لخدماتكم و عروضكم. لدى عملاء موقع حسومات ثقة عالية بمصداقيتنا
        لذلك خدماتنا و اعلاناتنا موثوقة بشكل كبير. نحن نقدم الفرصة للمعلن معنا
        لاستهداف و تنشيط العملاء الذين يعتمدون على المواقع لشراء عروضهم و
        خدماتهم من خلال تسويق و اعلانات عن طريق الايميل و المواقع و مواقع
        التواصل الاجتماعي. مع موقع حسومات، نقوم بتوفير الاعلانات المناسبة
        لخدماتكم و ايصال الاعلانات لجميع العملاء عن طريق الاعلانات الرقمية على
        الانترنت.
      </p>
    </div>

    <div class="en">
      <h2 class="page-title">Who Are We</h2>
      <p style=font-weight:bold;font-size:20px;>Saad Abdul Mohsen Al-Rossis Trading Group</p>
      <br />
      <p>
        * 7usoomat.com is a mobile app that offers quick and easy access to
        offers and merchant services on the go. It gives online shoppers the
        chance to stay up to date on great nearby experiences on the spot with a
        reliable push notification system\. 7usoomat.com is your premier
        destination for great new experiences at amazing grand discounts. Save
        more and Double the fun! 7usoomat.com is the leader in providing the
        best offers in the Kingdom of Saudi Arabia, our mission is to become the
        go-to savings and exploration spot for all the region"s online shoppers.
        We created an easy way to find the best offers from quality local and
        international stores online. 7usoomat.com is part of Saad Al Rossais
        Group with 3 operating offices in Jeddah, Riyadh, Dammam and soon across
        the Middle East. By becoming a member of 7usoomat.com,
      </p>

      <p>
        * you won"t just save money; you'll have a chance to sample exciting new
        places and unique experiences. 7usoomat.com is a win-win platform for
        customers and businesses. For offering a great deal on 7usoomat.com,
        businesses get pay-for-performance marketing at no risk, a customized
        campaign and secure a new income channel. Moreover, businesses will
        become the talk of the town by having their deal spread to thousands
        through emails and Facebook, Instagram and Twitter. 7usoomat.com
        believes in promoting quality local businesses offering positive
        experiences while benefiting the wider community. Both our members and
        merchants get amazing value. A few things you should know about how we
        do things:
      </p>

      <ul class="list" style="padding-left: 50px; padding-right: 0px">
        <li>We live local, we love local!</li>
        <li>
          We only feature products and services we would want to buy. In doing
          that, we follow a thorough selection process.
        </li>
        <li>
          If it ain"t a good deal, it won"t be featured on our site – that
          simple. • We don"t just sell deals! We offer members new experiences,
          great new places to discover at amazing discounts. If you buy a deal
          and are not satisfied, let us know. Our merchants and our service can
          only improve with your valuable feedback. We are not perfect, but we
          always strive to do things better.
        </li>
        <li>
          You should feel comfortable to go out and try the new experiences
          featured through 7usoomat.com. It"s safe, it"s a bargain, we enjoyed
          it, and so will you! • Our business is social and we want to make sure
          we are always friends. We have established social media channels to
          openly share updates and information with our members and partners.
          You are invited to participate.
        </li>
      </ul>

      <p>
        * Have a question or comment? We will make sure to answer you as fast as
        possible. Just like you, we hate slow and incompetent customer service.
        7usoomat.com is a better way to grow your business: 7usoomat.com can
        help you reach a new audience and grow your customer base by providing
        maximum exposure at absolutely no cost. There is no upfront cost when
        running a promotion with us. In fact, you"ll be earning revenue with
        each offer sold, and we"ll be the ones paying you while your promotion
        builds brand awareness through exposure to thousands through our website
        and social networks. We do understand the importance of driving new
        customers into your business. New customers are a necessity to expand
        your client base. We deliver these customers and it"s your opportunity
        to turn them into return customers. How it"s done? A 7usoomat.com
        representative will help you design a promotion tailored to your needs.
        Your promotion will be featured on our site, getting you in front of
        thousands of potential customers. When people start buying your
        promotion, you"ll be meeting a lot of new customers. So as mentioned
        earlier, it"s always a win-win situation with 7usoomat.com. Advertising
        your brand: Tap into our active audience with data-driven advertising
        solutions tailored to meet your marketing goals. 7usoomat.com offers
        brand advertisers a channel to engage our audience through targeted
        advertisements. We have a solution for everyone with site-wide
        advertising that can be targeted to your needs.
      </p>
      <p>
        * 7usoomat.com members trust our recommendation for the best things to
        try, making us a unique marketing partner. We offer the ability for our
        brand advertisers to target and engage consumers already shopping for
        new products, services, and experiences with a digital media campaign
        across email, web, and social media. With 7usoomat.com, we always run a
        campaign that makes sense for your brand. Drive customers to one of your
        existing promotions through impression-based ad placements on our
        website or advertise a new service to the right audience.
      </p>
    </div>
  </div>
</div>
