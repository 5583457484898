<!-- <div class="polcies-sec">
  <div class="container">
    <div class="ar">
      <h2 class="page-title">المقدمة والاحكام عامة</h2>

      <p class="polcies-text">
        أهلاً بكم في موقع حسومات الإلكتروني ، فيما يلي البنود والشروط التي تخص
        شروط الاستخدام والدخول لصفحات موقع حسومات او 7usoomat.com تعطى الكلمات
        والتعابير الواردة في هذه الاتفاقية المعاني المحددة لها كما يلي: حسومات:
        ويقصد بها مؤسسة دار مملكة الريادة لتقنيه المعومات. الموقع: يقصد به أينما
        ورد في الاتفاقية بموقع حسومات كما وتعتبر كافة الصفحات و الروابط والأدوات
        والخواص المتفرعة من الموقع والمتعلقة بالخدمات المقدمة منه جزء لا يتجزء
        من موقع حسومات الإلكتروني. العميل: ويقصد به الجهة التي تطلب الخدمة من
        الموقع سواء كانت هذه الجهة شخص طبيعي أو شخص أعتباري. الشركاء: ويقصد به
        الجهة التي تعلن عن منتجاتها وخدماتهاعلى موقع حسومات. الاتفاقية: ويقصد
        بها اتفاقية شروط واحكام استخدام موقع حسومات الإلكتروني والتي تتضمن كافة
        الاحكام والشروط الموضحة للعميل والتي تحكم وتنظم عملية إستخدام العميل
        لموقع حسومات الإلكتروني. يقر ويوافق العميل على ان إستخدامه للموقع يعتبر
        إقرار صريح وواضح ويفيد بقيامه بقراءة وفهم بنود وأحكام هذه الاتفاقية و
        طريقة إستخدام الموقع كما ويعتبر قبول وموافقة صريحة منه على شروط واحكام
        هذه الاتفاقية والاستجابة لها وهو بكامل الاهلية المعتبرة شرعاً وقانوناً.
        كما ويعتبر استخدام العميل للموقع اقرار صريح منه على الالتزام والاستجابة
        لكافة شروط واحكام الاتفاقية و وكافة الاحكام والشروط لخاصة بشركة حسومات
        7usoomat.com. من المعلوم للعميل بأنه وفي حالة إختيار شرط الموافقة، فإن
        أحكام وشروط الاتفاقية تعتبر سارية المفعول بمواجهة العميل.
      </p>
    </div>

    <div class="en">
      <h2 class="page-title">Privcy and Policy</h2>

      <p class="polcies-text">
        Welcome to 7usoomat.com. These are the terms and conditions governing
        your access to and use of the website 7usoomat.com and its related
        sub-domains, sites, services and tools (the “Site”). By accepting these
        terms and conditions, and by using the Site, you represent that you
        agree to comply with these terms and conditions with 7usoomat.com (“we”,
        “us” or “7usoomat.com”) in relation to your use of the Site (the “User
        Agreement”). This User Agreement is effective upon acceptance. If you do
        not agree to be bound by this User Agreement please do not access,
        register with or use this Site. Partners “: It means the party that
        advertise their products and services on 7usommat.com Before you may
        become or continue as a member of the Site, you must read, agree with
        and accept this User Agreement and 7usoomat.com’s Privacy Policy (the
        “Privacy Policy”). You should read this User Agreement and the Privacy
        Policy and access and read all further linked information referred to in
        this User Agreement, as such information contains further terms and
        conditions that apply to you as a user of 7usoomat.com. Such linked
        information including but not limited to the Privacy Policy is hereby
        incorporated by reference into this User Agreement.
      </p>
    </div>
  </div>
</div> -->
<div class="container" dir="ltr">
  <!-- <header class="u-clearfix u-header u-header" id="sec-cc8d">
    <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
      <a
        href="#"
        class="u-image u-logo u-image-1"
        data-image-width="1024"
        data-image-height="1024"
      >
        <img
          src="images/-02.png"
          class="u-logo-image u-logo-image-1"
          data-image-width="64"
        />
      </a>
      <nav class="u-menu u-menu-dropdown u-offcanvas u-menu-1">
        <div class="menu-collapse" style="font-size: 1rem; letter-spacing: 0px">
          <a
            class="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
            href="#"
          >
            <svg>
              <use
                xmlns:xlink="https://www.w3.org/1999/xlink"
                xlink:href="#menu-hamburger"
              ></use>
            </svg>
            <svg
              version="1.1"
              xmlns="https://www.w3.org/2000/svg"
              xmlns:xlink="https://www.w3.org/1999/xlink"
            >
              <defs>
                <symbol
                  id="menu-hamburger"
                  viewBox="0 0 16 16"
                  style="width: 16px; height: 16px"
                >
                  <rect y="1" width="16" height="2"></rect>
                  <rect y="7" width="16" height="2"></rect>
                  <rect y="13" width="16" height="2"></rect>
                </symbol>
              </defs>
            </svg>
          </a>
        </div>
        <div class="u-custom-menu u-nav-container">
          <ul class="u-nav u-unstyled u-nav-1">
            <li class="u-nav-item">
              <a
                class="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base active"
                href="#"
                style="padding: 10px 20px"
                >Privacy Policy</a
              >
            </li>
          </ul>
        </div>
        <div class="u-custom-menu u-nav-container-collapse">
          <div
            class="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav"
          >
            <div class="u-sidenav-overflow">
              <div class="u-menu-close"></div>
              <ul
                class="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2"
              >
                <li class="u-nav-item">
                  <a
                    class="u-button-style u-nav-link active"
                    href="Privacy-Policy.html"
                    style="padding: 10px 20px"
                    >Privacy Policy</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="u-black u-menu-overlay u-opacity u-opacity-70"></div>
        </div>
        <style class="offcanvas-style">
          .u-offcanvas .u-sidenav {
            flex-basis: 250px !important;
          }
          .u-offcanvas:not(.u-menu-open-right) .u-sidenav {
            margin-left: -250px;
          }
          .u-offcanvas.u-menu-open-right .u-sidenav {
            margin-right: -250px;
          }
          @keyframes menu-shift-left {
            from {
              left: 0;
            }
            to {
              left: 250px;
            }
          }
          @keyframes menu-unshift-left {
            from {
              left: 250px;
            }
            to {
              left: 0;
            }
          }
          @keyframes menu-shift-right {
            from {
              right: 0;
            }
            to {
              right: 250px;
            }
          }
          @keyframes menu-unshift-right {
            from {
              right: 250px;
            }
            to {
              right: 0;
            }
          }
        </style>
      </nav>
    </div>
  </header> -->
  <section class="u-align-left u-clearfix u-section-1" id="sec-7f8f">
    <div class="u-clearfix u-sheet u-sheet-1">
      <h1 class="fw-bolder text-center my-4">7usoomat Privacy Policy</h1>

      <p>
        7usoomat sa, SAE ("7usoomat sa") knows that you take your privacy
        seriously. We do, too. This Privacy Policy covers 7usoomat sa' treatment
        of personally identifiable information ("Personal Information") and
        other data that 7usoomat sa gathers when you are accessing 7usoomat sa'
        Service.
      </p>

      <p class="mb-4">Last Modified: 8&nbsp;<sup>th</sup>&nbsp;Dec 2022</p>

      <p><strong>Introduction</strong></p>

      <p>
        The 7usoomat | حسومات companies worldwide, its subsidiaries, affiliates
        (collectively, the "<strong>7usoomat | حسومات</strong>",&nbsp;<strong
          >"Company," "we," "us," or "our"</strong
        >) recognize the importance of protecting the privacy of all consumers
        ("<strong>you</strong>" or "<strong>user</strong>") who access and/or
        use, whether as a guest or a registered user, our websites, or portions
        of our websites, including any content, functionality and services
        offered on or through our websites, any webpages, our online or offline
        mobile applications that run on smart phones, tablets and other devices
        which provide dedicated non-browser-based interaction between you and
        our sites (all of which are collectively called
        our&nbsp;<strong>"Sites"</strong>).
      </p>

      <p>
        This Privacy Policy will inform you as to how we look after your
        personal data when you visit this and all of our Sites (regardless of
        where you visit it from) and tell you about your privacy rights and how
        the law protects you.
      </p>

      <p><strong>1. IMPORTANT INFORMATION AND WHO WE ARE</strong></p>

      <p><strong>PURPOSE OF THIS PRIVACY POLICY</strong></p>

      <p>
        This Privacy Policy aims to give you information on how the Company
        collects and processes your personal data through your use of any of its
        Sites, including any data you may provide through this Site when you
        register or sign in on any of our Sites, purchase a product or service
        or take part in a competition.
      </p>

      <p>
        It is important that you read this Privacy Policy together with any
        other privacy notice or fair processing notice we may provide on
        specific occasions when we are collecting or processing personal data
        about you so that you are fully aware of how and why we are using your
        data. This Privacy Policy supplements these other notices and is not
        intended to override them.
      </p>

      <p><strong>PERSONS UNDER AGE OF 13 YEARS</strong></p>

      <p>
        The Sites are not intended for children and we do not knowingly collect
        data relating to children. Our Sites are not intended for use by persons
        under 13 years of age without legal parental or guardian consent. No one
        under age 13 may provide any personal information to or on our Sites
        without legal parental or guardian consent. We do not knowingly collect
        personal information from persons under 13 if such information is
        provided without legal parental or guardian consent. If you are under
        13, do not use or provide any information on our Sites, or on or through
        any of their features register on the Sites, make any purchases through
        our Sites, use any of the interactive or public comment features of our
        Sites or provide any information about yourself to us, including your
        name, address, telephone number, e-mail address or any screen name or
        user name you may use without legal parental or guardian consent. If we
        learn we have collected or received personal information from a person
        under 13 without verification of parental consent, we will delete that
        information. If you believe we might have any information from or about
        a person under 13 for which there is no legal parental or guardian
        consent, please&nbsp;<a href="mailto:info@7usoomat.com">contact us</a>.
      </p>

      <p><strong>CONTROLLER</strong></p>

      <p>
        The Company is made up of different legal entities. This Privacy Policy
        is issued on behalf of the Company so when we mention
        "<strong>Company</strong>", "<strong>7usoomat | حسومات</strong>",
        "<strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>"
        in this Privacy Policy, we are referring to the relevant company
        responsible for processing your data.
      </p>

      <p>
        We have appointed a data protection officer (<strong>"DPO</strong>") who
        is responsible for overseeing questions in relation to this Privacy
        Policy. If you have any questions about this Privacy Policy, including
        any requests to exercise your legal rights, please contact the DPO using
        the details set out below.
      </p>

      <p><strong>CONTACT DETAILS</strong></p>

      <p>
        <strong><em>Attention: Data Protection Officer</em></strong>
      </p>

      <p>
        <strong
          ><em
            >Email address: info<a href="mailto:info@7usoomat.com">@</a
            >7usoomat.sa</em
          ></strong
        >
      </p>

      <p>
        You have the right to make a complaint at any time to the relevant
        supervisory authority for data protection issues. We would, however,
        appreciate the chance to deal with your concerns before you approach the
        relevant supervisory authority so please contact us in the first
        instance.
      </p>

      <p>
        For any questions or comments relating to this Privacy Policy or the
        manner in which we collect and use your personal data, please&nbsp;<a
          href="mailto:infio@7usoomat.com"
          >contact us</a
        >.
      </p>

      <p>
        <strong
          >CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF
          CHANGES</strong
        >
      </p>

      <p>
        This version was last updated on&nbsp;<strong>14 May 2018</strong
        >&nbsp;and historic versions can be obtained by&nbsp;<a
          href="mailto:info@7usoomat.com"
          >contacting us</a
        >.
      </p>

      <p>
        It is important that the personal data we hold about you is accurate and
        current. Please keep us informed if your personal data changes during
        your relationship with us.
      </p>

      <p>
        <strong
          >REQUESTS MADE UNDER THE EU DATA PROTECTION LAW PRIOR TO 25 MAY
          2018</strong
        >
      </p>

      <p>
        The data protection law in the European Union ("<strong>EU</strong>")
        will change on 25 May 2018. Although this Privacy Policy sets out most
        of your rights under the new laws applicable to you if you are resident
        of a country where such laws apply, we may not yet be able to respond to
        some of your requests (for example, a request for the transfer of your
        personal data) until 25 May 2018 as we are still working towards getting
        our systems ready for some of these changes. From 25 May 2018 onwards
        however any requests made by you under the data protection law
        applicable to us where you reside will be attended to by us in
        accordance with this Privacy Policy and the said data protection law.
      </p>

      <p><strong>THIRD-PARTY LINKS</strong></p>

      <p>
        The Sites may include links to third-party websites, plug-ins and
        applications. Clicking on those links or enabling those connections may
        allow third parties to collect or share data about you. We do not
        control these third-party websites and are not responsible for their
        privacy statements. When you leave our Sites, we encourage you to read
        the privacy policy of every website or other site you visit.
      </p>

      <p>
        <strong>2.</strong>&nbsp;<strong>THE DATA WE COLLECT ABOUT YOU</strong>
      </p>

      <p>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity has been removed (anonymous data).
      </p>

      <p>
        Depending on which of our products and/or services you use and how you
        interact with our Sites, we collect different kinds of information from
        or about you. We may collect, use, store and transfer different kinds of
        personal data about you which we have grouped together follows:
      </p>

      <ul>
        <li>
          <strong>Identity Data</strong>&nbsp;includes first name, last name,
          address, username or similar identifier, marital status, title, date
          of birth and gender.
        </li>
        <li>
          <strong>Contact Data</strong>&nbsp;includes billing address, delivery
          address, location, email address and/or telephone numbers.
        </li>
        <li>
          <strong>Financial Data</strong>&nbsp;includes bank account and payment
          card details, payment history.
        </li>
        <li>
          <strong>Transaction Data</strong>&nbsp;includes details about payments
          to and from you and other details of products and services you have
          purchased from us.
        </li>
        <li>
          <strong>Technical Data</strong>&nbsp;includes internet protocol (IP)
          address, your login data, browser type and version, make and model
          (mobile phones only), operating system, hardware version, platform,
          device settings and other technology identification on the devices
          used to access our Sites, file and software names and types, device
          identifiers, time zone setting and location, device locations such as
          through GPS, Bluetooth or WiFi signals, browser plug-in types and
          versions, operating system and platform, connection information such
          as the name of your mobile operator or ISP, browser type, language and
          time zone, mobile phone number and IP address.
        </li>
        <li>
          <strong>Profile Data&nbsp;</strong>includes<strong>&nbsp;</strong>your
          username and password, purchases or orders made by you, your
          interests, preferences, feedback and survey responses.
        </li>
        <li>
          <strong>Usage Data</strong>&nbsp;includes information about how you
          use our Sites, products and services including redemption of our
          offers, how you use your devices to access our Sites, including the
          screens you visit and searches you make.
        </li>
        <li>
          <strong>Special Categories of Personal Data</strong>&nbsp;includes
          details about your race or ethnicity or religion.
        </li>
        <li>
          <strong>Other Data</strong>&nbsp;includes:
          <ul>
            <li>
              photos or other data uploaded onto any of our Sites by you or
              shared with other users of our Sites by you via our Sites;
            </li>
            <li>
              communications with us via social media platforms, email,
              electronic messages and other electronic and non-electronic
              communications;
            </li>
            <li>
              third party partner information related to how you use our third
              party partners, such as your reviews of our merchant partners or
              other service providers.
            </li>
            <li>
              your networks and connections<strong>&nbsp;</strong>made available
              to us, depending on the permissions you have granted, from your
              mobile and desktop devices' address book contacts, and other
              social media such as Facebook, Instagram, Pinterest and Twitter.
            </li>
          </ul>
        </li>
        <li>
          Marketing and Communications Data includes your preferences in
          receiving marketing from us and our third parties and your
          communication preferences
        </li>
      </ul>

      <p>
        We also collect, use and share aggregated data such as statistical or
        demographic data for any purpose. Aggregated Data may be derived from
        your personal data but is not considered personal data in law as this
        data does&nbsp;<strong>not</strong>&nbsp;directly or indirectly reveal
        your identity. For example, we may aggregate your Usage Data to
        calculate the percentage of users accessing a specific Site feature.
        However, if we combine or connect aggregated data with your personal
        data so that it can directly or indirectly identify you, we treat the
        combined data as personal data which will be used in accordance with
        this Privacy Policy.
      </p>

      <p>
        We do not collect the following&nbsp;<strong
          >Special Categories of Personal Data</strong
        >: your philosophical beliefs, sex life, sexual orientation, political
        opinions, trade union membership, information about your health and
        genetic and biometric data. Nor do we collect any information about
        criminal convictions and offences.
      </p>

      <p><strong>IF YOU FAIL TO PROVIDE PERSONAL DATA</strong></p>

      <p>
        Where we need to collect personal data by law, or under the terms of a
        contract we have with you and you fail to provide that data when
        requested, we may not be able to perform the contract we have or are
        trying to enter into with you (for example, to provide you with goods or
        services). In this case, we may have to cancel a product or service you
        have with us but we will notify you if this is the case at the time.
      </p>

      <p><strong>3. HOW IS YOUR PERSONAL DATA COLLECTED?</strong></p>

      <p>
        We use different methods to collect data from and about you including
        through:
      </p>

      <p>
        You also may provide information to be published or displayed
        (hereinafter, "<strong>posted</strong>") on public areas of our Sites or
        transmitted to other users of our Sites or third parties (collectively,
        "<strong>User Contributions</strong>"). Your User Contributions are
        posted on and transmitted to others at your own risk. Although we limit
        access to certain pages, you may set certain privacy settings for such
        information by logging into your account profile or by&nbsp;<a
          href="mailto:info@7usoomat.com"
          >contacting us.</a
        >&nbsp;Please be aware that no security measures are perfect or
        impenetrable. Additionally, we cannot control the actions of other users
        of our Sites with whom you may choose to share your User Contributions.
        Therefore, we cannot and do not guarantee that your User Contributions
        will not be viewed by unauthorized persons.
      </p>

      <ul>
        <li>
          <strong>Direct interactions.</strong>&nbsp;You may give us your
          Identity, Contact and Financial Data by filling in forms or by
          corresponding with us by post, phone, email or otherwise. This
          includes personal data you provide when you:

          <ul>
            <li>apply for or purchase our products or services;</li>
            <li>create an account or register on our Sites;</li>
            <li>subscribe to our service or publications;</li>
            <li>
              request marketing to be sent to you or further services to be
              provided to you;
            </li>
            <li>enter a competition, promotion or survey;</li>
            <li>
              correspond with us (online or offline including via email
              addresses and phone numbers), if you contact us;
            </li>
            <li>post material on our Sites; or</li>
            <li>give us some feedback.</li>
          </ul>
        </li>
        <li>
          <strong>Automated technologies or interactions.</strong>&nbsp;As you
          interact with our Sites, we may automatically collect Technical Data
          about your equipment, browsing actions and patterns. We collect this
          personal data by using cookies, server logs, web beacons, and other
          similar technologies. We may also receive Technical Data about you if
          you visit other Sites employing our cookies.
          <p>
            We do not collect personal information automatically, but we may tie
            this information to personal information about you that we collect
            from other sources or you provide to us.
          </p>

          <ol style="list-style-type: lower-alpha">
            <li>
              <strong>Cookies</strong>: We automatically capture some
              information through the use of cookies. A cookie is a small file
              that is placed onto your desktop or mobile device when you visit
              our Sites in order to enable certain features and functionality of
              our Sites. Our cookies can be categorized into four types, each of
              which is outlined below

              <ul>
                <li>
                  <strong>Category 1: Strictly necessary cookies</strong>. These
                  cookies are essential for enabling our Sites to provide
                  certain of your requested services such as retaining your
                  shopping bag items.
                </li>
                <li>
                  <strong>Category 2: Performance cookies</strong>. Performance
                  cookies collect anonymous information on the users of our
                  Sites to enable us to improve the operation of our Sites. For
                  example, we utilise cookies to help us understand how
                  customers arrive at and browse or use our Sites, and highlight
                  improvement areas such as navigation, shopping experience and
                  marketing. The data stored by these cookies does not include
                  personal details from which an individual's identity can be
                  established.
                </li>
                <li>
                  <strong>Category 3: Functionality cookies</strong>. These
                  cookies retain choices you make such as member ID, email ID
                  and other preferences in order to help you avoid having to
                  re-enter the information as you navigate through our Sites,
                  including the shopping cart.
                </li>
                <li>
                  <strong>Category 4: Targeting or advertising cookies</strong>.
                  These cookies collect information about your browsing habits
                  to allow us to provide advertising relevant to you and your
                  interests. The cookies retain information about the websites
                  you have visited. This information is shared with other
                  parties such as advertisers on an anonymous basis. Your
                  personally identifiable information such as your name and
                  address is not shared with such third parties. For example, we
                  use third party companies to provide you with more
                  personalised advertisements when you visit certain third party
                  websites.
                </li>
              </ul>
            </li>
            <li>
              <strong>Web beacons</strong>: Pages of our Sites and our e-mails
              may contain small electronic files known as web beacons (also
              referred to as clear gifs, pixel tags and single-pixel gifs) that
              permit the Company, for example, to count users who have visited
              those pages or opened an e-mail and collect other related website
              and app statistics (for example, recording the popularity of
              certain app content and verifying system and server integrity).
            </li>
            <li>
              <strong>Log files</strong>: We may use log files to capture your
              activities on our apps as they interact with our Sites.
              Information captured in such log files may include the types of
              content you view or engage with and the frequency or duration of
              your activities.
            </li>
          </ol>
        </li>
        <li>
          <strong>Third parties or publicly available sources.</strong>&nbsp;We
          may receive personal data about you from various third parties and
          public sources as set out below:
          <ul>
            <li>
              Technical Data from the following parties:
              <ul>
                <li>
                  <ul>
                    <li>
                      analytics providers based either inside or outside the EU;
                    </li>
                    <li>
                      advertising networks based either inside or outside the
                      EU;
                    </li>
                    <li>
                      search information providers based either inside or
                      outside the EU; or
                    </li>
                    <li>
                      third parties we contract with and/or their subsidiaries
                      and affiliates to provide you a service based either
                      inside or outside the EU
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Contact, Financial and Transaction Data from providers of
              technical, payment and delivery services based either inside or
              outside the EU.
            </li>
            <li>
              Identity and Contact Data from data brokers or aggregators based
              either inside or outside the EU.
            </li>
            <li>
              Identity and Contact Data from publicly availably sources based
              either inside or outside the EU.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        <strong>4.</strong>&nbsp;<strong>HOW WE USE YOUR PERSONAL DATA</strong>
      </p>

      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following circumstances:
      </p>

      <ul>
        <li>
          Where we need to perform the contract we are about to enter into or
          have entered into with you.
        </li>
        <li>
          Where we need to perform a contract we have with a third party to
          provide you a product or service.
        </li>
        <li>
          Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.
        </li>
        <li>Where we need to comply with a legal or regulatory obligation.</li>
      </ul>

      <p>
        Generally, we do not rely on consent as a legal basis for processing
        your personal data other than in relation to sending third party direct
        marketing communications to you via email, text message or other means.
        You have the right to withdraw consent to marketing at any time
        by&nbsp;<a href="mailto:info@7usoomat.com">contacting us</a>.
      </p>

      <p><strong>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</strong></p>

      <p>
        We have set out below, in a table format, a description of all the ways
        we plan to use your personal data, and which of the legal bases we rely
        on to do so. We have also identified what our legitimate interests are
        where appropriate.
      </p>

      <p>
        Note that we may process your personal data for more than one lawful
        ground depending on the specific purpose for which we are using your
        data. Please&nbsp;<a href="mailto:info@7usoomat.com">contact us</a>.
      </p>

      <p>
        if you need details about the specific legal ground we are relying on to
        process your personal data where more than one ground has been set out
        in the table below.
      </p>

      <table>
        <tbody>
          <tr>
            <td style="vertical-align: top">
              <p><strong>Purpose/Activity</strong></p>
            </td>
            <td style="vertical-align: top">
              <p><strong>Type of data</strong></p>
            </td>
            <td style="vertical-align: top">
              <p>
                <strong
                  >Lawful basis for processing including basis of legitimate
                  interest</strong
                >
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>To register you as a new customer/user</p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Profile<br />
                (d) Special Categories of Personal Data
              </p>
            </td>
            <td style="vertical-align: top">
              <ol>
                <li>Performance of a contract with you</li>
              </ol>

              <p>(b) Necessary to comply with a legal obligation</p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To process and deliver your order including:<br />
                (a) Manage payments, fees and charges<br />
                (b) Collect and recover money owed to us
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Financial<br />
                (d) Transaction<br />
                (e) Marketing and Communications
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Performance of a contract with you<br />
                (b) Necessary for our legitimate interests (to recover debts due
                to us)
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To manage our relationship with you which will include:<br />
                (a) Notifying you about changes to our terms or privacy
                policy<br />
                (b) Asking you to leave a review or take a survey<br />
                (c) communicate with you about our products and services and
                respond to you when you contact us
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Profile<br />
                (d) Marketing and Communications
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Performance of a contract with you<br />
                (b) Necessary to comply with a legal obligation<br />
                (c) Necessary for our legitimate interests (to keep our records
                updated and to study how customers use our products/services)
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To enable you to partake in a prize draw, competition or
                complete a survey
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Profile<br />
                (d) Usage<br />
                (e) Marketing and Communications
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Performance of a contract with you<br />
                (b) Necessary for our legitimate interests (to study how
                customers use our products/services, to develop them and grow
                our business)
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To administer and protect our business and the Sites (including
                troubleshooting, improvement, data analysis, testing, system
                maintenance, security, support, reporting and hosting of data)
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Technical
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise)<br />
                (b) Necessary to comply with a legal obligation
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To deliver relevant Site content and advertisements to you and
                measure or understand the effectiveness of the advertising we
                serve to you
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Profile<br />
                (d) Usage<br />
                (e) Marketing and Communications<br />
                (f) Technical
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                Necessary for our legitimate interests (to study how customers
                use our products/services, to develop them, to grow our business
                and to inform our marketing strategy)
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To deliver better and more personalised Site content so that you
                have a beneficial and valuable experience when using our Sites,
                products and/or services
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Profile<br />
                (d) Usage<br />
                (e) Marketing and Communications<br />
                (f) Technical
              </p>
            </td>
            <td style="vertical-align: top">
              <p>(a) Performance of a contract with you</p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To enable you to utilize a feature of a product or service
                provided to you.
              </p>
            </td>
            <td style="vertical-align: top">
              <ol>
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
              </ol>
            </td>
            <td style="vertical-align: top">
              <p>(a) Performance of a contract with you</p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To use data analytics to improve our Sites, products/services,
                marketing, customer relationships and experiences
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Technical<br />
                (c) Usage<br />
                (d) Transaction<br />
                (e) Special Categories of Personal Data
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Necessary for our legitimate interests (to define types of
                customers for our products and services, to keep our Sites
                updated and relevant, to develop our business and to inform our
                marketing strategy)<br />
                (b) Necessary to comply with a legal obligation
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                To make suggestions and recommendations to you about goods or
                services that may be of interest to you
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Identity<br />
                (b) Contact<br />
                (c) Technical<br />
                (d) Usage<br />
                (e) Profile
              </p>
            </td>
            <td style="vertical-align: top">
              <p>
                Necessary for our legitimate interests (to develop our
                products/services and grow our business)
              </p>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">
              <p>
                Any other purpose disclosed by us when you provide the personal
                data
              </p>
            </td>
            <td style="vertical-align: top">
              <p>As applicable</p>
            </td>
            <td style="vertical-align: top">
              <p>
                (a) Performance of a contract with you<br />
                (b) Necessary to comply with a legal obligation
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p><strong>MARKETING</strong></p>

      <p>
        We strive to provide you with choices regarding certain personal data
        uses, particularly around marketing and advertising.
      </p>

      <p>
        <br />
        <strong>Direct Marketing by the Company&nbsp;</strong>If you do not wish
        to have your personal data used by the Company to promote our own or
        third parties' products or services, you can opt-out by&nbsp;<a
          href="mailto:info@7usoomat.com"
          >contacting us</a
        >. If we have sent you a promotional e-mail, you may send us a return
        e-mail asking to be omitted from future e-mail distributions. This opt
        out does not apply to information provided to the Company as a result of
        a product or service purchase, product service experience or other
        transactions.
      </p>

      <p>
        <strong>Targeted Advertising.</strong>&nbsp;If you do not want us to use
        information that we collect or that you provide to us to deliver
        advertisements according to our advertisers' target-audience
        preferences, you can opt-out by Contacting us.
      </p>

      <p><strong>PROMOTIONAL OFFERS FROM US</strong></p>

      <p>
        We may use your Identity, Contact, Technical, Usage and Profile Data and
        Special Categories of Personal Data to form a view on what we think you
        may want or need, or what may be of interest to you. This is how we
        decide which products, services and offers may be relevant for you (we
        call this marketing).
      </p>

      <p>
        You will receive marketing communications from us if you have requested
        information from us or purchased goods or services from us or if you
        provided us with your details when you entered a competition or
        registered for a promotion and, in each case, you have not opted out of
        receiving that marketing.
      </p>

      <p><strong>THIRD-PARTY MARKETING</strong></p>

      <p>
        We will get your express opt-in consent before we share your personal
        data with any company outside the Company for marketing purposes. We do
        not control third parties' collection or use of your information to
        serve interest-based advertising. However, these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way.
      </p>

      <p><strong>OPTING OUT</strong></p>

      <p>
        You can ask us or third parties to stop sending you marketing messages
        at any time by logging into the website and checking or unchecking
        relevant boxes to adjust your marketing preferences or by following the
        opt-out links on any marketing message sent to you or by&nbsp;<a
          href="mailto:info@7usoomat.com"
          >contacting us</a
        >&nbsp;at any time.
      </p>

      <p>
        Where you opt out of receiving these marketing messages, this will not
        apply to personal data provided to us as a result of a product/service
        purchase, warranty registration, product/service experience or other
        transactions.
      </p>

      <p><strong>COOKIES</strong></p>

      <p>
        You can set your browser to refuse all or some browser cookies, or to
        alert you when websites set or access cookies. If you disable or refuse
        cookies, please note that some parts of our Sites may become
        inaccessible or not function properly. For more information about the
        cookies we use, please see&nbsp;<strong
          >Section 3 – How is your Personal Data collected</strong
        >&nbsp;above.
      </p>

      <p><strong>LOCATION INFORMATION</strong></p>

      <p>
        You can choose whether or not to allow our Sites to collect and use
        real-time information about your device's location through the device's
        privacy settings. If you block the use of location information, some
        parts of our Sites may then be inaccessible or not function properly.
      </p>

      <p><strong>CHANGE OF PURPOSE</strong></p>

      <p>
        We will only use your personal data for the purposes for which we
        collected it, unless we reasonably consider that we need to use it for
        another reason and that reason is compatible with the original purpose.
        If you wish to get an explanation as to how the processing for the new
        purpose is compatible with the original purpose, please&nbsp;<a
          href="mailto:info@7usoomat.com"
          >contact us.</a
        >
      </p>

      <p>
        If we need to use your personal data for an unrelated purpose, we will
        notify you and we will explain the legal basis which allows us to do so.
      </p>

      <p>
        Please note that we may process your personal data without your
        knowledge or consent, in compliance with the above rules, where this is
        required or permitted by law.
      </p>

      <p>
        <strong>5.</strong>&nbsp;<strong
          >DISCLOSURES OF YOUR PERSONAL DATA</strong
        >
      </p>

      <p>
        We may have to share your personal data with the parties set out below
        for the purposes set out in the table in paragraph 4 above.
      </p>

      <ul>
        <li>
          Internal Third Parties as set out in&nbsp;<strong
            >Section 10 – Glossary</strong
          >&nbsp;below.
        </li>
        <li>
          External Third Parties as set out in&nbsp;<strong
            >Section 10 – Glossary</strong
          >&nbsp;below.
        </li>
        <li>
          Third parties listed in the table in&nbsp;<strong
            >Section 4 – How we use your Personal Data</strong
          >&nbsp;above;
        </li>
        <li>
          Third parties to whom we may choose to sell, transfer, or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this Privacy Policy.
        </li>
        <li>
          To a third party you nominate to fulfil the purpose for which you
          provide it. For example, if you give us an e-mail address to use the
          "e-mail a friend" feature of our Sites or you nominate another person
          to become a secondary member in family account, we will transmit the
          contents of that e-mail and your e-mail address to the recipients.
        </li>
      </ul>

      <p>
        We require all third parties to respect the security of your personal
        data and to treat it in accordance with the law. We do not allow our
        third-party service providers to use your personal data for their own
        purposes and only permit them to process your personal data for
        specified purposes and in accordance with our instructions.
      </p>

      <p><strong>6.</strong>&nbsp;<strong>INTERNATIONAL TRANSFERS</strong></p>

      <p>
        We share your personal data within the Company. This will involve
        transferring your data outside the European Economic Area
        (<strong>EEA</strong>).
      </p>

      <p>
        We ensure your personal data is protected by requiring all our group
        companies to follow the same rules when processing your personal data.
      </p>

      <p>
        Many of our external third parties are based outside the European
        Economic Area (<strong>EEA</strong>) so their processing of your
        personal data will involve a transfer of data outside the EEA.
      </p>

      <p>
        Whenever we transfer your personal data out of the EEA, we ensure a
        similar degree of protection is afforded to it by ensuring at least one
        of the following safeguards is implemented:
      </p>

      <ul>
        <li>
          We will only transfer your personal data to countries that have been
          deemed to provide an adequate level of protection for personal data by
          the European Commission.
        </li>
        <li>
          Where we use certain service providers, we may use specific contracts
          which give personal data the same protection it has in Europe.
        </li>
        <li>
          Where we use providers based in the US, we may transfer data to them
          if they are part of the Privacy shield which requires them to provide
          similar protection to personal data shared between Europe and the US.
        </li>
      </ul>

      <p>
        Please&nbsp;<a href="mailto:info@7usoomat.com">contact us i</a>f you
        want further information on the specific mechanism used by us when
        transferring your personal data out of the EEA.
      </p>

      <p><strong>7.</strong>&nbsp;<strong>DATA SECURITY</strong></p>

      <p>
        We have put in place appropriate security measures to prevent your
        personal data from being accidentally lost, used or accessed in an
        unauthorised way, altered or disclosed. In addition, we limit access to
        your personal data to those employees, agents, contractors and other
        third parties who have a business need to know. They will only process
        your personal data on our instructions and they are subject to a duty of
        confidentiality.
      </p>

      <p>
        We have put in place procedures to deal with any suspected personal data
        breach and will notify you and any applicable regulator of a breach
        where we are legally required to do so.
      </p>

      <p><strong>8.</strong>&nbsp;<strong>DATA RETENTION</strong></p>

      <p><strong>HOW LONG WILL YOU USE MY PERSONAL DATA FOR?</strong></p>

      <p>
        We will only retain your personal data for as long as necessary to
        fulfil the purposes we collected it for, including for the purposes of
        satisfying any legal, accounting, or reporting requirements.
      </p>

      <p>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </p>

      <p>
        In some circumstances we may anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes in which case we may use this information indefinitely without
        further notice to you.
      </p>

      <p><strong>9.</strong>&nbsp;<strong>YOUR LEGAL RIGHTS</strong></p>

      <p>
        Under certain circumstances, you have rights under data protection laws
        in relation to your personal data. These include the following
        (see&nbsp;<strong>section 10 – Glossary</strong>&nbsp;below):
      </p>

      <ul>
        <li><em>Request access to your personal data</em></li>
        <li><em>Request correction of your personal data</em>.</li>
        <li><em>Request erasure of your personal data</em>.</li>
        <li><em>Object to processing of your personal data</em>.</li>
        <li><em>Request restriction of processing your personal data</em>.</li>
        <li><em>Request transfer of your personal data</em>.</li>
        <li><em>Right to withdraw consent</em>.</li>
      </ul>

      <p>
        If you wish to exercise any of the rights set out above, please&nbsp;<a
          href="mailto:info@7usoomat.com"
          >contact us.</a
        >
      </p>

      <p><strong>NO FEE USUALLY REQUIRED</strong></p>

      <p>
        You will not have to pay a fee to access your personal data (or to
        exercise any of the other rights). However, we may charge a reasonable
        fee if your request is clearly unfounded, repetitive or excessive.
        Alternatively, we may refuse to comply with your request in these
        circumstances.
      </p>

      <p><strong>WHAT WE MAY NEED FROM YOU</strong></p>

      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </p>

      <p><strong>TIME LIMIT TO RESPOND</strong></p>

      <p>
        We try to respond to all legitimate requests within one month.
        Occasionally it may take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this
        case, we will notify you and keep you updated.
      </p>

      <p><strong>10.</strong>&nbsp;<strong>GLOSSARY</strong></p>

      <p><strong>LAWFUL BASIS</strong></p>

      <p>
        <strong>Legitimate Interest</strong>&nbsp;means the interest of our
        business in conducting and managing our business to enable us to give
        you the best service/product and the best and most secure experience. We
        make sure we consider and balance any potential impact on you (both
        positive and negative) and your rights before we process your personal
        data for our legitimate interests. We do not use your personal data for
        activities where our interests are overridden by the impact on you
        (unless we have your consent or are otherwise required or permitted to
        by law). You can obtain further information about how we assess our
        legitimate interests against any potential impact on you in respect of
        specific activities by&nbsp;<a href="mailto:info@7usoomat.com"
          >contacting us.</a
        >
      </p>

      <p>
        <strong>Performance of Contract</strong>&nbsp;means processing your data
        where it is necessary for the performance of a contract to which you are
        a party or to take steps at your request before entering into such a
        contract.
      </p>

      <p>
        <strong>Comply with a legal or regulatory obligation</strong>&nbsp;means
        processing your personal data where it is necessary for compliance with
        a legal or regulatory obligation that we are subject to.
      </p>

      <p><strong>THIRD PARTIES</strong></p>

      <p><strong>INTERNAL THIRD PARTIES</strong></p>

      <p>
        Our companies, their subsidiaries and affiliates acting as joint
        controllers or processors who provide IT and system administration
        services and undertake reporting.
      </p>

      <p><strong>EXTERNAL THIRD PARTIES</strong></p>

      <ul>
        <li>
          Service providers acting as processors who provide IT and system
          administration services or who we use to support our business and who
          are bound by contractual obligations to keep personal data
          confidential and secure and use it only for the purposes for which we
          disclose it to them.
        </li>
        <li>
          Professional advisers acting as processors or joint controllers
          including lawyers, bankers, auditors and insurers who provide
          consultancy, banking, legal, insurance and accounting services.
        </li>
        <li>
          HM Revenue &amp; Customs, regulators and other authorities acting as
          processors or joint controllers who require reporting of processing
          activities in certain circumstances.
        </li>
        <li>
          Any other party, their subsidiaries and affiliates which we have
          contracted with to provide you with a service and/or product.
        </li>
      </ul>

      <p><strong>YOUR LEGAL RIGHTS</strong></p>

      <p>You have the right to:</p>

      <ul style="list-style-type: disc">
        <li>
          <strong>Request access&nbsp;</strong>to your personal data (commonly
          known as a "<strong>data subject access request</strong>"). This
          enables you to receive a copy of the personal data we hold about you
          and to check that we are lawfully processing it.
        </li>
        <li>
          <strong>Request correction&nbsp;</strong>of the personal data that we
          hold about you. This enables you to have any incomplete or inaccurate
          data we hold about you corrected, though we may need to verify the
          accuracy of the new data you provide to us.
        </li>
        <li>
          <strong>Request erasure&nbsp;</strong>of your personal data. This
          enables you to ask us to delete or remove personal data where there is
          no good reason for us continuing to process it. You also have the
          right to ask us to delete or remove your personal data where you have
          successfully exercised your right to object to processing (see below),
          where we may have processed your information unlawfully or where we
          are required to erase your personal data to comply with local law.
          Note, however, that we may not always be able to comply with your
          request of erasure for specific legal reasons which will be notified
          to you, if applicable, at the time of your request.
        </li>
        <li>
          <strong>Object to processing&nbsp;</strong>of your personal data where
          we are relying on a legitimate interest (or those of a third party)
          and there is something about your particular situation which makes you
          want to object to processing on this ground as you feel it impacts on
          your fundamental rights and freedoms. You also have the right to
          object where we are processing your personal data for direct marketing
          purposes. In some cases, we may demonstrate that we have compelling
          legitimate grounds to process your information which override your
          rights and freedoms.
        </li>
        <li>
          <strong>Request restriction of processing&nbsp;</strong>of your
          personal data. This enables you to ask us to suspend the processing of
          your personal data in the following scenarios: (a) if you want us to
          establish the data’s accuracy; (b) where our use of the data is
          unlawful but you do not want us to erase it; (c) where you need us to
          hold the data even if we no longer require it as you need it to
          establish, exercise or defend legal claims; or (d) you have objected
          to our use of your data but we need to verify whether we have
          overriding legitimate grounds to use it.
        </li>
        <li>
          <strong>Request the transfer&nbsp;</strong>of your personal data to
          you or to a third party. We will provide to you, or a third party you
          have chosen, your personal data in a structured, commonly used,
          machine-readable format. Note that this right only applies to
          automated information which you initially provided consent for us to
          use or where we used the information to perform a contract with you.
        </li>
        <li>
          <strong>Withdraw consent at any time&nbsp;</strong>where we are
          relying on consent to process your personal data. However, this will
          not affect the lawfulness of any processing carried out before you
          withdraw your consent. If you withdraw your consent, we may not be
          able to provide certain products or services to you. We will advise
          you if this is the case at the time you withdraw your consent.
        </li>
      </ul>

      <p><strong>© the 7usoomat | حسومات All rights reserved.</strong></p>
    </div>
  </section>

  <footer
    class="u-align-center u-clearfix u-footer u-grey-80 u-footer"
    id="sec-254d"
  >
    <div class="u-clearfix u-sheet u-sheet-1">
      <p class="u-small-text u-text u-text-variant u-text-1">
        7usoomat sa&nbsp;
      </p>
    </div>
  </footer>
</div>
