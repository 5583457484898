import { LocationService } from 'src/app/core/services/location/location.service';
import { HomeOptions, HomeDetails } from './../../core/models/home.model';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CategoryData } from 'src/app/core/models/category.model';
import { HomeService } from 'src/app/core/services/home/home.service';
import { OffersService } from 'src/app/core/services/offers/offers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoresService } from 'src/app/core/services/stores/stores.service';
import { BrowserService } from 'src/app/core/services/browser/browser.service';
import { PROFILE } from 'src/app/core/models/profile.model';
import { ProfileService } from 'src/app/core/services/user/profile/profile.service';
import { LocalStorgeService } from 'src/app/core/services/user/local/local-update.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  categoriesCardsColors = ['#FFFCEB', '#FEEFEA', '#FFF3FF', '#F2FCE4'];
  categories: CategoryData;
  mainSliderItems;
  avalibleOffersSections;
  homeOptions: HomeOptions;
  homeAd;
  ProfileData: PROFILE;
  showProfileData = false;
  showHomeAdPop = false;
  homeDetails: HomeDetails = {
    app_starting_ads: {
      action: 'offer',
      type: 'app_starting',
      web_image_ar: '../../../assets/images/error-img.svg',
      time_between_each_time: 1,
      num_of_times: 3,
      value: '1136',
    },
  };
  latestAdCounts: number;
  showStartingAds = false;

  mainSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true,
    rtl: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  categoriesSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoWidth: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    autoplay: true,
    rtl: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 3,
      },
      740: {
        items: 6,
      },
      940: {
        items: 10,
      },
    },
    nav: true,
  };

  constructor(
    private route: ActivatedRoute,
    private homeServices: HomeService,
    private offerSerice: OffersService,
    private storeService: StoresService,
    private locationService: LocationService,
    private lsService: LocalStorgeService,
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthService,
    private browserService: BrowserService
  ) {}

  ngOnInit(): void {
    this.browserService.updateBrowserTitle('');
    this.homeInit();
    this.getHomeDetails();
    this.getParams();
    this.getQueyParams();

    if (
      this.router.url == '/home/login' &&
      !this.authService.isAuthenticated()
    ) {
      this.authService.showAuth.next(true);
    }
  }

  private getParams(): void {
    this.route.params.subscribe((params) => {
      if (params.offerId) {
        this.openOffer(params.offerId);
      }
    });
  }

  private getQueyParams(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.subDone && queryParams.subDone == 1) {
        this.profileDataHandler();
      }
    });
  }

  // get background colorof cat
  currentIndexOfBackgroundColor = -1;
  getRendomBackgroundColor() {
    const catBackgroundLength = this.categoriesCardsColors.length - 1;
    this.currentIndexOfBackgroundColor =
      this.currentIndexOfBackgroundColor !== catBackgroundLength
        ? ++this.currentIndexOfBackgroundColor
        : 0;

    return this.categoriesCardsColors[this.currentIndexOfBackgroundColor];
  }

  //  get offers & categories & slider
  homeInit() {
    const coordinatins = this.locationService.getLocalCoordinates();
    this.homeOptions = {
      lat: coordinatins.lat,
      lng: coordinatins.lng,
      web: 1,
    };
    this.homeServices.getHomeData(this.homeOptions).subscribe((res: any) => {
      this.categories = res.data
        .filter((x) => x.type === 'categories')[0]
        .data.map((cat) => ({
          ...cat,
          backgroundColor: this.getRendomBackgroundColor(),
        }));
      this.mainSliderItems = res.data.filter(
        (x) => x.type === 'slider'
      )[0]?.data;
      this.avalibleOffersSections = res.data.filter((x) => x.type === 'offers');
    });
  }

  //  show offer details
  openOffer(offerId) {
    this.offerSerice.getOfferDetails(offerId).subscribe((res: any) => {
      this.offerSerice.offerDetailsOpen.next(res.data);
    });
  }

  //  get ads
  getHomeDetails() {
    this.homeServices.getHomeDetails().subscribe((res: any) => {
      this.homeDetails = res.data;

      if (
        this.homeDetails.app_starting_ads &&
        this.router.url != '/home/login'
      ) {
        this.startingAdsInit();
      }

      if (this.homeDetails.home_ads && this.homeDetails.home_ads.length > 0) {
        this.getRandomHomeAd(this.homeDetails.home_ads);
      }
    });
  }

  // starting ads sec
  startingAdsInit() {
    // data from response
    const adsCounts = this.homeDetails.app_starting_ads.num_of_times;
    const adsTime = this.homeDetails.app_starting_ads.time_between_each_time;
    const adImage = this.homeDetails.app_starting_ads.web_image_ar;

    // data from local storage
    const localAdData = this.homeServices.getStartingAdData();
    this.latestAdCounts = localAdData.counts;
    const latestAdTime = localAdData.time;
    const localAdImage = localAdData.image ? localAdData.image : adImage;

    // compare data to get condtion of appearance of ad
    const currentTime = Date.now();
    const isExceedCounts = this.latestAdCounts <= adsCounts;
    const isExceedTime = currentTime - latestAdTime > adsTime * 60000;
    const isSameImageAd = localAdImage === adImage;

    if (isSameImageAd) {
      if (this.latestAdCounts == 1) {
        this.showStartingAds = true;
      }

      if (isExceedCounts && isExceedTime) {
        this.showStartingAds = true;
      }
    } else {
      this.homeServices.updateStartingAdData(
        1,
        this.homeDetails.app_starting_ads.web_image_ar
      );
      this.showStartingAds = true;
    }
  }

  openAds(actionType, actionValue, adType) {
    if (adType == 'starting app ads') {
      this.showStartingAds = false;
    } else {
      this.showHomeAdPop = false;
    }
    switch (actionType) {
      case 'external_link':
        window.open(actionValue, '_blank').focus();
        break;

      case 'category':
        this.router.navigate(['/offers', actionValue]);
        break;

      case 'offer':
        this.openOffer(actionValue);
        break;

      case 'store':
        this.storeService.getStoreDetails(actionValue).subscribe((res: any) => {
          this.storeService.storeDetailsOpen.next(res.data);
        });
        break;

      default:
        this.router.navigate(['/home']);
    }
  }

  getRandomHomeAd(ads) {
    if (ads.length > 1) {
      this.homeAd = ads[Math.floor(Math.random() * ads.length)];
    } else {
      this.homeAd = ads[0];
    }
    this.showHomeAdPop = true;
  }

  onCloseAd() {
    this.homeServices.updateStartingAdData(
      ++this.latestAdCounts,
      this.homeDetails.app_starting_ads.web_image_ar
    );
    this.showStartingAds = false;
  }

  profileDataHandler() {
    const userData = this.lsService.getItem('userData');
    if (userData) {
      this.profileService
        .getProfileData({ user_id: userData.id })
        .subscribe((profile: any) => {
          this.ProfileData = profile.data;
          this.showProfileData = true;
          setTimeout(() => (this.showProfileData = false), 5000);
        });
    }
  }
}
