import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorgeService {
  constructor() {}

  updateLocalUserInfo(key, value) {
    let userInfo = JSON.parse(localStorage.getItem('hossoatUserInfo'));
    userInfo = { ...userInfo, [key]: value };
    localStorage.setItem('hossoatUserInfo', JSON.stringify(userInfo));
  }

  getLocalUserInfo() {
    return JSON.parse(localStorage.getItem('hossoatUserInfo'));
  }

  getLocalItem(key) {
    const userinfo = JSON.parse(localStorage.getItem('hossoatUserInfo'));

    if (userinfo && userinfo[key]) {
      return userinfo[key];
    } else {
      return null;
    }
  }

  isItemExist(name) {
    const item = localStorage.getItem(name);
    return item ? true : false;
  }

  setItem(name, value) {
    const item = JSON.stringify(value);
    localStorage.setItem(name, item);
  }

  deleteItem(name) {
    localStorage.removeItem(name);
  }

  getItem(name) {
    if (this.isItemExist(name)) {
      const item = JSON.parse(localStorage.getItem(name));
      return item;
    } else {
      return null;
    }
  }
}
