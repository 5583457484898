import { AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { BrowserService } from '../../core/services/browser/browser.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Payment } from 'src/app/core/models/payment.model';
import { PackagesService } from 'src/app/core/services/packages/packages.service';
import { Package } from 'src/app/core/models/package.model';
import { CitiesService } from 'src/app/core/services/cities/cities.service';
import { PaymentService } from 'src/app/core/services/payment/payment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css'],
})
export class SubscriptionsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  packages: Package[] = [];
  questions = [
    {
      parentName:
        'What is the difference between the packages available for subscription?',
      childProperties: [
        {
          propertyName: 'The difference is in the subscription period only',
        },
      ],
    },
    {
      parentName:
        'Can I take advantage of all the offers when subscribing to any package?',
      childProperties: [
        {
          propertyName:
            'When subscribing, you can benefit from all the offers available in the application according to the terms of each offer',
        },
      ],
    },
    {
      parentName: 'How can I contact you?',
      childProperties: [
        {
          propertyName:
            'Contact through the unified number 920000881 or mobile number 0544664666',
        },
      ],
    },
  ];
  paymentData: Payment = {
    type: 'package',
  };

  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  paymentMethods = [
    {
      title: 'Visa / Master / Mada',
      id: 1,
      icon: 'assets/images/payments/visa.png',
      macSupport: true,
    },
    {
      title: 'Apple Pay',
      id: 2,
      icon: 'assets/images/payments/apple.png',
      macSupport: this.isSafari,
    },
  ];
  selectedPackage: { id?: string; name?: string } = {};
  preRequestData = [];
  cities = [];
  showPreRequest = false;
  showVoucherInput = false;
  disabledPaymentsMethods = false;
  voucherChecked = false;
  selectedCoupon = '';
  wrongCoupon = false;
  wrongVoucher = false;
  showPayment = false;
  paymentURL = '';
  paymentSub: Subscription;
  constructor(
    private browserService: BrowserService,
    private packagesService: PackagesService,
    private citiesService: CitiesService,
    private paymentService: PaymentService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.browserService.updateBrowserTitle(' - الاشتراكات');
    this.getAllPackages();
    this.paymentSub = this.paymentService.paymentClosed.subscribe(
      (isClosed) => (this.showPayment = isClosed)
    );
  }

  ngAfterViewInit(): void {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  getAllPackages() {
    this.packagesService.getAllPackages().subscribe((res: any) => {
      this.packages = res.data;
      this.getPreRequestData(
        `${this.packages[0].id}`,
        this.packages[0].title,
        ''
      );
    });
  }

  getAllCities() {
    this.citiesService
      .getAllCities()
      .subscribe((res: any) => (this.cities = res.data));
  }

  getPreRequestData(package_id: string, packageName: string, code: string) {
    if (this.authService.isAuthenticated()) {
      this.wrongCoupon = false;
      if (this.cities.length == 0) {
        this.getAllCities();
      }

      this.selectedPackage = { name: packageName, id: package_id };
      this.paymentData.package_id = package_id;
      this.packagesService
        .getPreRequest({ package_id, code })
        .subscribe((res: any) => {
          if (res.data.coupon_id) {
            this.paymentData.coupon_id = res.data.coupon_id;
          }
          this.preRequestData = res.data;

          if (res.data.discount === 'general.invalid_coupon') {
            this.wrongCoupon = true;
            this.paymentData.coupon = '';
          }

          this.paymentData.total_amount = res.data.prices.filter(
            (el) => el.type === 'total_order'
          )[0].value;
          this.showPreRequest = true;
        });

      this.resetFormData();
    } else {
      this.authService.showAuth.next(true);
    }
  }

  getPreRequestDataWithCopon(coupon) {
    if (coupon !== this.paymentData.coupon) {
      this.paymentData.coupon = coupon;
      this.getPreRequestData(
        this.selectedPackage.id,
        this.selectedPackage.name,
        coupon
      );
    }
  }

  onVoucherCheckChange(event) {
    const checked = event.target.checked;
    if (checked) {
      this.voucherChecked = true;
      this.showVoucherInput = true;
      this.disabledPaymentsMethods = true;
      delete this.paymentData.payment_id;
    } else {
      this.voucherChecked = false;
      this.showVoucherInput = false;
      this.disabledPaymentsMethods = false;
    }
  }

  resetFormData() {
    this.voucherChecked = false;
    this.showVoucherInput = false;
    this.disabledPaymentsMethods = false;
  }

  onPayment() {
    if (this.authService.isAuthenticated()) {
      this.wrongVoucher = false;
      if (this.voucherChecked) {
        if (this.paymentData.voucher_code) {
          this.paymentData.paid_price = this.paymentData.total_amount;
          this.paymentData.pay_type = 'voucher';
          delete this.paymentData.coupon;

          this.packagesService.packageSubscripe(this.paymentData).subscribe({
            next: (res) => {
              this.router.navigate(['/home'], { queryParams: { subDone: 1 } });
            },
            error: (error) => (this.wrongVoucher = true),
          });
        }
      } else {
        this.showPayment = true;
        // visa card case
        if (this.paymentData.payment_id == '1') {
          this.paymentService.initialSession().subscribe((res) => {
            this.paymentData.sessionId = res.data.SessionId;
            this.paymentData.countryCode = res.data.CountryCode;
            this.paymentService.initialSessionDone.next(this.paymentData);
          });
        } else {
          // apple pay case
          this.paymentService.initialSession().subscribe((res) => {
            this.paymentData.sessionId = res.data.SessionId;
            this.paymentData.countryCode = res.data.CountryCode;
            this.paymentService.initialSessionDone.next(this.paymentData);
          });
        }
      }
    } else {
      this.authService.showAuth.next(true);
    }
  }

  ngOnDestroy(): void {
    this.paymentSub.unsubscribe();
  }
}
