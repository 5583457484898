import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactusComponent implements OnInit, OnDestroy {
  languageSub: Subscription;
  currentLang;
  contactForm!: FormGroup;

  constructor(private langService: LanguageService) {}

  ngOnInit(): void {
    this.languageSub = this.langService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );

    this.initForm();
  }

  initForm() {
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      checked: new FormControl('', Validators.required),
    });
  }

  ngOnDestroy(): void {
    this.languageSub.unsubscribe();
  }

  onSubmit() {
    console.log(this.contactForm.value);
  }
}
