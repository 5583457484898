import { BrowserService } from './../../core/services/browser/browser.service';
import { OfferOptions } from './../../core/models/offers.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffersService } from 'src/app/core/services/offers/offers.service';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';
import { LocationService } from 'src/app/core/services/location/location.service';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { StoresService } from 'src/app/core/services/stores/stores.service';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css'],
})
export class OffersComponent implements OnInit {
  currentLang;
  categoryId;
  offers;
  FilterForm: FormGroup;
  categories = [];
  categoryName;
  currentCategory;
  totalPages;
  itemsPerPage;
  currentPage;
  totalItems;
  currentCatId = 1;
  currentCatAds;
  showFilter = false;
  notItemsAvalible = false;
  discountChecked: boolean;
  offersChecked: boolean;
  sellerChecked: boolean;
  OfferFilter: OfferOptions = {
    page: 1,
  };

  constructor(
    private route: ActivatedRoute,
    private offersService: OffersService,
    private storeService: StoresService,
    private activated: ActivatedRoute,
    private router: Router,
    private categoriesService: CategoriesService,
    private locationService: LocationService,
    private langService: LanguageService,
    private browserService: BrowserService
  ) {}

  ngOnInit(): void {
    this.browserService.updateBrowserTitle(' - العروض');
    this.OfferFilter.location = this.locationService.getLocalCoordinates();
    if (this.categories.length == 0) {
      this.getAllCategories();
    } else {
      this.getParams();
    }
    this.getQueryParams();
    this.initForm();
    this.langService.languageChanged.subscribe(
      (lang) => (this.currentLang = lang)
    );
  }

  initForm() {
    this.FilterForm = new FormGroup({
      highest_discount: new FormControl(''),
      new_offers: new FormControl(''),
      best_seller: new FormControl(''),
      price_order: new FormControl('asc'),
    });
  }

  private getParams(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.OfferFilter.category_id = params.id;

        this.currentCategory = this.categories.filter(
          (el) => el.id == params?.id
        )[0];
        this.currentCatAds = this.categories.filter(
          (el) => el.id == params?.id
        )[0].ads;

        this.categoryName = this.currentCategory?.name;
      } else {
        this.OfferFilter.category_id = this.categories[0].id;
        this.currentCategory = this.categories[0];
        this.currentCatAds = this.categories.filter(
          (el) => el.id == this.categories[0].id
        )[0].ads;
        this.categoryName = this.categories[0].name;
      }
      this.getOffers();
    });
  }

  private getQueryParams(): void {
    this.activated.queryParams.subscribe((qParams) => {
      if (qParams['page']) {
        this.OfferFilter.page = +qParams['page'] || 1;
        this.getOffers();
      }
    });
  }

  getOffers() {
    this.offersService.getOffers(this.OfferFilter).subscribe((res: any) => {
      this.offers = res.data.data;
      this.totalPages = res.data.total_pages;
      this.itemsPerPage = res.data.per_page;
      this.currentPage = res.data.current_page;
      this.totalItems = res.data.total;

      this.notItemsAvalible = this.offers.length === 0 ? true : false;
    });
  }

  getAllCategories() {
    this.categoriesService.getAllCategories().subscribe((res: any) => {
      this.categories = res.data.data;
      this.getParams();
    });
  }

  openOffer(offerId) {
    this.storeService.offerDetailsOpen.next(offerId);
  }

  onPageChange(page) {
    this.currentPage = +page;
    this.OfferFilter.page = +page;
    this.router.navigate([], { queryParams: { page } });
  }

  setFilterItem(filterItem, value) {
    if (value == 1) {
      this.OfferFilter[filterItem] = +value;
    } else {
      delete this.OfferFilter[filterItem];
    }
  }

  openAds(actionType, actionValue) {
    switch (actionType) {
      case 'external_link':
        window.open(actionValue, '_blank').focus();
        break;

      case 'category':
        this.router.navigate(['/offers', actionValue]);
        break;

      case 'offer':
        this.openOffer(actionValue);
        break;

      case 'store':
        this.storeService.getStoreDetails(actionValue).subscribe((res: any) => {
          this.storeService.storeDetailsOpen.next(res.data);
        });
        break;

      default:
        this.router.navigate(['/home']);
    }
  }

  SubmitFilters() {
    this.OfferFilter.page = 1;
    this.showFilter = false;
    this.getOffers();
  }

  customSearch(searchForm: NgForm) {
    this.OfferFilter.page = 1;
    this.OfferFilter.keyword = searchForm.value['offers_search'];
    this.getOffers();
  }

  checkFilterActivation() {
    if (
      this.OfferFilter.best_seller == '1' ||
      this.OfferFilter.highest_discount == '1' ||
      this.OfferFilter.new_offers == '1' ||
      this.OfferFilter.price_order != undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearFilter() {
    this.OfferFilter = {
      page: 1,
    };
    this.offersChecked = false;
    this.discountChecked = false;
    this.sellerChecked = false;
    this.showFilter = false;
    this.getOffers();
  }
}
