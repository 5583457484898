import { isObject } from 'rxjs/internal/util/isObject';

import { LocalStorgeService } from './../user/local/local-update.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'accept-language': localStorage.getItem('lang')
        ? localStorage.getItem('lang')
        : 'ar',
      'app-version': '1',
      'device-name': 'chrome',
      'device-os-version': 'windows',
      'device-udid': '123',
      'device-type': 'website',
      'device-push-token': 'Not Allowed',
      'city-id': `${this.lsService.getLocalItem('city_id') || 1}`,
    }),
    withCredentials: false,
  };

  private domain = 'https://manage.7usoomat.sa/api/';
  // private domain = 'http://168f-102-44-187-55.ngrok.io/api/';
  // private domain = 'http://192.168.1.11:5000/api/';
  private formData: FormData;

  constructor(
    private httpSender: HttpClient,
    private lsService: LocalStorgeService
  ) {}

  getData(
    path: string,
    params?: {},
    headers?: { name: string; value: string }
  ) {
    if (params) {
      path += this.getArgs(params);
    }

    if (headers) {
      this.httpOptions.headers.append(
        `${this.domain}${headers.name}`,
        headers.value
      );
    }

    return this.httpSender.get(`${this.domain}${path}`, this.httpOptions);
  }

  post(path?: string | number, body: any = {}, params?: {}): Observable<any> {
    if (params) {
      path += this.getArgs(params);
    }
    return this.httpSender.post(
      `${this.domain}${path}`,
      body,
      this.httpOptions
    );
  }

  /**
   * Serializin arguments as a string
   * @param options object of Backend parametars to serialize
   * @return string of parameters
   */
  getArgs(options: any): string {
    if (!options) {
      return '';
    }
    var args = '?';
    Object.keys(options).forEach((key, index) => {
      args += this.optionToString(key, options[key]);
    });
    return args;
  }

  /**
   * serializing eatch option
   * @param key option key
   * @param value option value
   * @return single option serilization
   */
  optionToString(key: string, value: any): string {
    if (value == null || value == undefined) {
      return '';
    }
    var str = '';
    if (value instanceof Array) {
      value.forEach((element, index) => {
        str += `${key}[${index}]=${element}&`;
      });
    } else if (value instanceof Object) {
      Object.keys(value).forEach((element, index) => {
        if (value instanceof Object) {
          str += this.serializeObject(value[element], `${key}[${element}]`);
        } else {
          str += `${key}[${element}]=${value[element]}&`;
        }
      });
    } else {
      str += `${key}=${value}&`;
    }
    return str;
  }

  /**
   * serializing the object keys
   * @param obj object to serialize
   */
  private serializeObject(obj: any, parentSerialized: string): string {
    var str = '';

    if (obj instanceof Object) {
      Object.keys(obj).forEach((key, index) => {
        const value = obj[key];
        if (value instanceof Object) {
          str += `${this.serializeObject(
            value,
            `${parentSerialized}[${key}]`
          )}`;
        } else {
          str += `${parentSerialized}[${key}]=${value}&`;
        }
      });
    } else {
      str += `${parentSerialized}=${obj}&`;
    }

    return str;
  }

  postFormData(
    resource?: string | number,
    body: any = {},
    params?: {}
  ): Observable<any> {
    if (params) {
      resource += this.getArgs(params);
    }

    const HttpUploadOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'accept-language': 'ar',
        'app-version': '1',
        'device-name': 'chrome',
        'device-os-version': 'windows',
        'device-udid': '123',
        'device-type': 'web',
        'device-push-token': 'Not Allowed',
      }),
    };

    this.formData = new FormData();
    this.toFormData(body);

    return this.httpSender.post(
      `${this.domain}${resource}`,
      this.formData,
      HttpUploadOptions
    );
  }

  private toFormData(body): FormData {
    Object.keys(body).forEach((key) => {
      if (body[key] !== '' && body[key] !== null) {
        if (Array.isArray(body[key])) {
          this.arrayToFormData(body[key], key);
        } else if (isObject(body[key]) && !body[key].lastModified) {
          Object.keys(body[key]).forEach((el) => {
            if (body[key][el]) {
              this.formData.append(key + '[' + el + ']', body[key][el]);
            }
          });
        } else {
          this.formData.append(key, body[key]);
        }
      }
    });
    return this.formData;
  }

  private arrayToFormData(array, arrayName) {
    if (array.length) {
      array.forEach((el, i) => {
        if (el instanceof Object) {
          Object.keys(el).forEach((elKey) => {
            this.formData.append(arrayName + `[${i}][${elKey}]`, el[elKey]);
          });
        } else {
          if (el) {
            this.formData.append(arrayName + `[${i}]`, el);
          }
        }
      });
    }
  }
}
