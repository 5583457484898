<div class="container" [ngClass]="{ en: currentLang == 'en' }">
  <section class="subscription">
    <img
      src="../../../assets/images/subscription.JPG"
      alt="subscription-banner"
    />

    <div class="sub-input">
      <form class="d-flex">
        <input
          class="form-control me-2"
          type="text"
          placeholder=" {{ 'Email' | translate }} "
        />
        <button class="btn sub-btn" type="submit">
          {{ "Subscripe Now" | translate }}
        </button>
      </form>
    </div>
  </section>

  <section class="featured">
    <div class="featured-container">
      <div class="featured-item">
        <div class="featured-img">
          <img src="../../../assets/images/featured/icon-1.svg" />
        </div>
        <div class="featured-desc">
          <h4 class="main-title">{{ "Big Daily Deals" | translate }}</h4>
          <p class="sub-title">{{ "When You Register" | translate }}</p>
        </div>
      </div>

      <div class="featured-item">
        <div class="featured-img">
          <img
            src="../../../assets/images/featured/icon-2.svg"
            alt="featured"
          />
        </div>
        <div class="featured-desc">
          <h4 class="main-title">{{ "Free Delivery" | translate }}</h4>
          <p class="sub-title">{{ "Amazing Services 24/7" | translate }}</p>
        </div>
      </div>

      <div class="featured-item">
        <div class="featured-img">
          <img
            src="../../../assets/images/featured/icon-3.svg"
            alt="featured"
          />
        </div>
        <div class="featured-desc">
          <h4 class="main-title">{{ "Best Prices and Offers" | translate }}</h4>
          <p class="sub-title">{{ "Fabulous Prices" | translate }}</p>
        </div>
      </div>

      <div class="featured-item">
        <div class="featured-img">
          <img
            src="../../../assets/images/featured/icon-4.svg"
            alt="featured"
          />
        </div>
        <div class="featured-desc">
          <h4 class="main-title">{{ "Safe Delivery" | translate }}</h4>
          <p class="sub-title">{{ "Within 30 Days" | translate }}</p>
        </div>
      </div>

      <div class="featured-item">
        <div class="featured-img">
          <img
            src="../../../assets/images/featured/icon-5.svg"
            alt="featured"
          />
        </div>
        <div class="featured-desc">
          <h4 class="main-title">{{ "Easy Return" | translate }}</h4>
          <p class="sub-title">{{ "Within 30 Days" | translate }}</p>
        </div>
      </div>

      <div class="featured-item">
        <div class="featured-img">
          <img
            src="../../../assets/images/featured/icon-6.svg"
            alt="featured"
          />
        </div>
        <div class="featured-desc">
          <h4 class="main-title">{{ "Wide Variety" | translate }}</h4>
          <p class="sub-title">{{ "Huge Discounts" | translate }}</p>
        </div>
      </div>
    </div>
  </section>
</div>
<footer>
  <div class="container">
    <div class="top-footer">
      <div class="footer-col">
        <div class="logo">
          <img src="../../../assets/images/logo.png" alt="logo" />
          <div class="app-desc">
            {{
              "Husoomat is an mobile application to facilitate the process of obtaining offers and discounts from the stores available within the application in an easy and simple way. Discounts give you a great opportunity to save, and the experience is the best proof"
                | translate
            }}
          </div>
          <div class="communications">
            <div class="link">
              <img
                src="../../../assets/images/icons/icon-contact.svg"
                alt="icon"
                class="icon"
              />
              <p class="title">{{ "Contact Us" | translate }}</p>
              <a class="desc" href="tel:966558183409" dir="ltr"
                >+966558183409</a
              >
            </div>
            <div class="link">
              <img
                src="../../../assets/images/icons/icon-location.svg"
                alt="icon"
                class="icon"
              />
              <p class="title">{{ "Email" | translate }}</p>
              <a href="mailto: customersupport@7usoomat.com" class="desc"
                >customersupport@7usoomat.com</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="footer-col">
        <h3 class="col-title">{{ "Links" | translate }}</h3>
        <ul class="col-list">
          <li class="list-item">
            <a href="https://www.7usoomat.com" target="_blank">{{
              "For Advertising" | translate
            }}</a>
          </li>
          <li class="list-item">
            <a href="https://www.7usoomat.com" target="_blank">{{
              "Support" | translate
            }}</a>
          </li>
          <li class="list-item">
            <a [routerLink]="['/joinUs']">
              {{ "Add Your Store" | translate }}
            </a>
          </li>
          <li class="list-item">
            <a [routerLink]="['/contactus']">{{ "Contact Us" | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-col">
        <h3 class="col-title">{{ "Quick Links" | translate }}</h3>
        <ul class="col-list">
          <li class="list-item">
            <a [routerLink]="['/policies']">{{
              "Privacy Policy" | translate
            }}</a>
          </li>
          <li class="list-item">
            <a [routerLink]="['/membership']">
              {{ "Membership Qualification" | translate }}
            </a>
          </li>
          <li class="list-item">
            <a [routerLink]="['/terms']">{{
              "Terms and Conditions" | translate
            }}</a>
          </li>
          <li class="list-item">
            <a [routerLink]="['/aboutus']">{{ "Who We Are" | translate }}</a>
          </li>
        </ul>
      </div>

      <div class="footer-col downloads-col">
        <h3 class="col-title">{{ "Download App" | translate }}</h3>
        <ul class="col-list">
          <li class="list-item">
            <a href="https://bit.ly/ios7usoomat" target="_blank">
              <img
                src="assets/images/mobile-apps/appstore.svg"
                alt="app-store"
                class="download-link"
              />
            </a>
          </li>
          <li class="list-item">
            <a href="https://bit.ly/and7usoomat" target="_blank">
              <img
                src="assets/images/mobile-apps/googleplay.svg"
                alt="google-play"
                class="download-link"
              />
            </a>
          </li>
          <li class="list-item">
            <a href="https://bit.ly/huw7usoomat" target="_blank">
              <img
                src="assets/images/mobile-apps/huwawi.svg"
                alt="google-play"
                class="download-link"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="bottom-footer">
      <div class="right-side">
        <div class="socials">
          <h4>{{ "Follow Us" | translate }}</h4>
          <div class="socials-links">
            <a href="https://www.facebook.com/7usoomat" class="link facebook">
              <img src="assets/images/socials/facebook.svg" alt="social-link" />
            </a>

            <a href="https://instagram.com/7usoomat" class="link insta">
              <img src="assets/images/socials/insta.svg" alt="social-link" />
            </a>

            <a href="https://twitter.com/7usoomat" class="link twitter">
              <img src="assets/images/socials/twitter.svg" alt="social-link" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCxv1dqDrmq9ZtfdlTriFGJw"
              class="link youtube"
            >
              <img src="assets/images/socials/youtube.svg" alt="social-link" />
            </a>
          </div>
        </div>

        <span class="subscription-dicount d-block mt-2">
          {{ "Up to 15% off for your first subscription" | translate }}
        </span>
      </div>

      <div class="middle-side d-none d-lg-block">
        <div class="hotline">
          <img
            src="../../../assets/images/icons/icon-headphone.svg"
            alt="hotline"
          />
          <div class="hotline-text">
            <a dir="ltr" class="num" href="tel:558183409">(966) 558183409 </a>
            <span class="text">24/7 Support Center</span>
          </div>
        </div>
      </div>

      <div class="rights">
        <p>Copyright @{{ year }} All Rights Reserved 7usoomat.com</p>
      </div>
    </div>
  </div>
</footer>
