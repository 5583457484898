import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { FacilitiesService } from 'src/app/core/services/facilities/facilities.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Facility } from './../../core/models/facility.model';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css'],
})
export class JoinUsComponent implements OnInit {
  contractURL;
  facilityData: Facility = {};
  contract: any;
  constructor(
    private homeService: HomeService,
    private alertService: AlertService,
    private facilityService: FacilitiesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.homeService
      .getHomeDetails()
      .pipe(take(1))
      .subscribe((res: any) => {
        this.contractURL = res.data.contract_url;
      });
  }

  fileChange(event) {
    const files = event.target.files;
    this.facilityData.contract = files[0];
  }

  isFormValid() {
    if (this.facilityData.contract) {
      return false;
    } else {
      return true;
    }
  }

  onSubmit() {
    this.facilityService.addFacility(this.facilityData).subscribe({
      next: (res) => {
        if (res.code === 200 && res.errors === null) {
          this.alertService.showAlert.next({
            type: 'success',
            message: res.message,
          });
        } else {
          this.alertService.showAlert.next({
            type: 'error',
            message: res.message,
          });
        }
      },
      error: (error) => {
        this.alertService.showAlert.next({
          type: 'error',
          message: error.error.message,
        });
      },
    });
  }
}
