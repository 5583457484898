<div
  class="disable-background"
  (click)="closeAlert()"
  [ngClass]="{ show: showAlert }"
></div>

<div class="alert-block" *ngIf="showAlert">
  <button
    type="button"
    class="btn-close align-self-end"
    aria-label="Close"
    (click)="closeAlert()"
  ></button>
  <img
    src="assets/images/icons/success-alert.svg"
    class="alert-img"
    alt="alert"
    *ngIf="!isError"
  />
  <img
    src="assets/images/icons/error-alert.svg"
    class="alert-img"
    alt="alert"
    *ngIf="isError"
  />
  <h2 class="alert-header success" *ngIf="!isError">
    {{ "Successful Operation" | translate }}
  </h2>
  <h2 class="alert-header error" *ngIf="isError">
    {{ "Error Occurred" | translate }}
  </h2>
  <p class="alert-message">
    {{ alertMessage }}
  </p>
</div>
