import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  OfferDetails,
  OfferOptions,
  SpecialOfferOptions,
} from '../../models/offers.model';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  offerDetailsOpen = new Subject<OfferDetails>();
  constructor(private httpService: HttpService) {}

  getOffers(options: OfferOptions) {
    return this.httpService.getData(`offers`, options);
  }

  getOfferDetails(offerId) {
    return this.httpService.getData(`offers/${offerId}`);
  }

  getSpecialOffers(offerUrl) {
    return this.httpService.getData(offerUrl);
  }
}
