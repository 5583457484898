import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-accordation',
  templateUrl: './app-accordation.component.html',
  styleUrls: ['./app-accordation.component.css'],
})
export class AppAccordationComponent implements OnInit, OnDestroy {
  @Input() accordData: { parentName: string; childProperties: [] }[];
  data: any;
  currenLang;
  langSubscription: Subscription;
  constructor(private langService: LanguageService) {}

  ngOnInit(): void {
    this.data = this.accordData;
    this.langSubscription = this.langService.languageChanged.subscribe(
      (lang) => (this.currenLang = lang)
    );
  }

  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle('active');
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }
}
