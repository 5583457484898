import { LocalStorgeService } from './../user/local/local-update.service';
import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { HomeOptions } from '../../models/home.model';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private HttpService: HttpService,
    private localService: LocalStorgeService
  ) {}

  getHomeData(options: HomeOptions): any {
    const cityId = this.localService.getLocalItem('city_id');
    return this.HttpService.getData('home/mobile', options, {
      name: 'city-id',
      value: cityId ? cityId : 1,
    });
  }

  getHomeDetails() {
    return this.HttpService.getData('home/website');
  }

  getStartingAdData() {
    const adCounts = this.localService.getLocalItem('start_ad_counts');
    const adTime = this.localService.getLocalItem('start_ad_time');
    const adImage = this.localService.getLocalItem('start_ad_image');
    if (adCounts && adTime) {
      return {
        counts: adCounts,
        time: adTime,
        image: adImage,
      };
    } else {
      this.updateStartingAdData(1);
      return {
        counts: 1,
        time: Date.now() + 300000,
      };
    }
  }

  updateStartingAdData(counts, adImage?) {
    this.localService.updateLocalUserInfo('start_ad_counts', counts);
    this.localService.updateLocalUserInfo('start_ad_time', Date.now());

    if (adImage) {
      this.localService.updateLocalUserInfo('start_ad_image', adImage);
    }
  }
}
